workloadCalculatorApp.controller('workloadCalculatorCtrl', ['$scope', '$timeout', ($scope, $timeout) ->
  window.debug = window.debug || {}
  window.debug["workloadCalculatorCtrl"] = $scope

  dt = 1 # numDays

  minStudyPerItem = 1.0 / 3

  _.assignIn $scope, 
    maxDays: 1000
    itemsEligible:    {} # date indexed integers
    averageIntervals: {} # date indexed floats
    itemsAtLevel:     {} # date indexed objects
    schedule:         [] # items

    initialItems: 30     # # of facets in level = 0 at time = 0
    initialItemFrequencyWeeks: 1
    initialItemDurationWeeks: 4
    initialInterval: .25  # 6 hours

    minimumInterval: 4.5 / 24.0
    targetRetention: 0.85 # Retention/Activation at time of review
    targetLevel: 2.5        # target Cerego level
    ticksToTarget: 0

    incorrectFactors: [.25]
    correctFactors: [1.1, 1.9, 1.9, 2.75, 1.5, 2.25, 2.25, 3.25]

    levels: [
      name: "n"
      interval: 0.0
      numeric: 0.0
    ,
      name: "b"
      interval: 1.0 / 3.0
      numeric: 0.3
    ,
      name: "1"
      interval: 1
      numeric: 1.0
    ,
      name: "2"
      interval: 5
      numeric: 2.0
    ,
      name: "3"
      interval: 21
      numeric: 3.0
    ,
      name: "4"
      interval: 3*30
      numeric: 4.0
    ,
      name: "5"
      interval: 9*30
      numeric: 5.0
    ]

    timeFor: (ticks) ->
      date = new Date()
      new Date(date.getTime() + ticks * dt * 24 * 60 * 60 * 1000)

    addNewItem: (schedule, tick) ->
      schedule.push
        interval: $scope.initialInterval
        reviewTick: tick + ($scope.initialInterval / dt)

    range: (n) ->
      _.range(n)

    mode: "student"

    madlibMinutes: 30
    madlibItems: 180
    madlibWeeks: 8

    planNewItems: ->
      newItems = if $scope.planWeeks($scope.madlibMinutes / minStudyPerItem / 2.4) >= 7
        $scope.madlibMinutes / minStudyPerItem / 2.4
      else if $scope.planWeeks($scope.madlibMinutes / minStudyPerItem / 2) >= 4
        $scope.madlibMinutes / minStudyPerItem / 2
      else if $scope.planWeeks($scope.madlibMinutes / minStudyPerItem / 1.6) >= 2
        $scope.madlibMinutes / minStudyPerItem / 1.6
      else
        $scope.madlibMinutes || 0
      Math.round(Math.min(newItems, $scope.madlibItems))

    planWeeks: (newItems) ->
      if _.isUndefined(newItems)
        newItems = $scope.planNewItems()
      Math.ceil($scope.madlibItems / newItems)

    planRetentionMinutes: ->
      validDays = Math.max(0, $scope.itemsEligible.length - 7*$scope.initialItemDurationWeeks)
      sum = _.reduce _.last($scope.itemsEligible, validDays), (outerSum, tick) ->
        outerSum + _.reduce _.values(tick), (innerSum, i) ->
          innerSum + i
        , 0
      , 0
      average = Math.round(sum / validDays) # double our estimate cause why not
      if _.isNaN(average)
        1
      else
        Math.max(1, average)

    planRetentionWeeks: ->
      weeks = Math.round(($scope.itemsEligible.length - 7*$scope.initialItemDurationWeeks) / 7)
      if _.isNaN(weeks)
        1
      else
        weeks

    runSimulation: ->
      schedule = []
      averageIntervals = {}
      itemsEligible = {}
      cumItemsAtLevel = {}
      itemsAtLevel = {}

      levelFor = (interval) ->
        _.reduceRight $scope.levels, (bestLevel, level)->
          bestLevel || (interval >= level.interval && level.name)
        , 0

      initialItems = if _.isString($scope.initialItems) then parseInt($scope.initialItems) else $scope.initialItems
      initialItemFrequencyWeeks = if _.isString($scope.initialItemFrequencyWeeks) then parseInt($scope.initialItemFrequencyWeeks) else $scope.initialItemFrequencyWeeks
      initialItemDurationWeeks = if _.isString($scope.initialItemDurationWeeks) then parseInt($scope.initialItemDurationWeeks) else $scope.initialItemDurationWeeks

      maxDays = if $scope.mode == 'student' then $scope.maxDays else parseInt($scope.madlibWeeks) * 7

      for tick in $scope.range(maxDays / dt)
        # Populate initial items
        ticksPerWeek = 7 / dt
        if ((tick % (initialItemFrequencyWeeks * ticksPerWeek)) == 0) && (tick < initialItemDurationWeeks * ticksPerWeek)
          n = Math.min($scope.madlibItems - schedule.length, initialItems)
          _.times n, ->
            $scope.addNewItem(schedule, tick)

        # Select eligble items
        items = _.filter schedule, (item) ->
          item.reviewTick <= tick

        itemsEligible[tick] = _.countBy items, (item) ->
          levelFor(item.interval)

        study = (item, factor) ->
          item.interval *= factor
          item.interval = Math.max($scope.minimumInterval, item.interval)
          item.reviewTick = tick + (item.interval / dt)

        # studyItems
        for item in items
          if Math.random() <= $scope.targetRetention
            study(item, _.sample($scope.correctFactors))
          else
            study(item, _.sample($scope.incorrectFactors))

        averageIntervals[tick] = _.reduce(schedule, (memo, item) ->
          memo + item.interval
        , 0) * 1.0 / schedule.length

        itemsAtLevel[tick] = _.countBy schedule, (item) ->
          levelFor(item.interval)
        cumItemsAtLevel[tick] = {}
        for level in $scope.levels
          items = _.filter schedule, (item) ->
            item.interval >= level.interval
          cumItemsAtLevel[tick][level.name] = items.length

        $scope.ticksToTarget = tick
        sum = _.reduce $scope.levels, (sum, level) ->
          sum += cumItemsAtLevel[tick][level.name] * level.numeric
        , 0.0

        if sum > schedule.length * $scope.targetLevel
          break

      $scope.schedule = schedule
      $scope.averageIntervals = averageIntervals
      $scope.itemsEligible = _.map itemsEligible, (d) ->
        for level, val of d
          d[level] = Math.round(val * minStudyPerItem)
        d
      $scope.itemsAtLevel = itemsAtLevel
      $scope.cumItemsAtLevel = cumItemsAtLevel

  debouncedRun = ->
    if $scope.simulationRunning
      $timeout debouncedRun, 100
    else
      $scope.simulationRunning = true
      $scope.runSimulation()
      $timeout ->
        $scope.simulationRunning = false
      , 100

  $timeout ->
    debouncedRun()

  $scope.$watch "initialItems", (newVal, oldVal) ->
    debouncedRun() if newVal != oldVal

  $scope.$watch "targetLevel", (newVal, oldVal) ->
    debouncedRun() if newVal != oldVal

  $scope.$watch "initialItemFrequencyWeeks", (newVal, oldVal) ->
    debouncedRun() if newVal != oldVal

  $scope.$watch "initialItemDurationWeeks", (newVal, oldVal) ->
    debouncedRun() if newVal != oldVal

  $scope.$watch "mode", (newVal, oldVal) ->
    debouncedRun() if newVal != oldVal

  $scope.$watch "madlibMinutes", (newVal, oldVal) ->
    $scope.initialItems = $scope.planNewItems()
    $scope.initialItemFrequencyWeeks = 1
    $scope.initialItemDurationWeeks = $scope.planWeeks()

  $scope.$watch "madlibItems", (newVal, oldVal) ->
    $scope.initialItems = $scope.planNewItems()
    $scope.initialItemFrequencyWeeks = 1
    $scope.initialItemDurationWeeks = $scope.planWeeks()

  $scope.$watch "madlibWeeks", (newVal, oldVal) ->
    $scope.initialItems = $scope.planNewItems()
    $scope.initialItemFrequencyWeeks = 1
    $scope.initialItemDurationWeeks = $scope.planWeeks()
    debouncedRun() if newVal != oldVal
])
