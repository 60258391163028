memoryBank.service("progressVisual", ['$rootScope', '$filter', '$window', '$timeout', '$interval', ($rootScope, $filter, $window, $timeout, $interval) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["progressVisual"] = $scope

  NEEDS_REVIEW_RETENTION = .85
  HEADER_HEIGHT = 60
  LEFT_BORDER_WIDTH = 40
  RIGHT_BORDER_WIDTH = 0
  BOX_SIZE = 20
  SVG_WIDTH = 100
  RECT_WIDTH = 32
  MOBILE_TOP_PADDING = if angular.element($window).width() < 768 then 10 else 0
    
  STAR_CONTAINER_SIZE = 100
  STAR_SIZE = 50
  STAR_INITIAL_SCALE = 0.4
  STAR_Y_OFFSET = 10 + STAR_SIZE / 2
  STAR_X_OFFSET = STAR_CONTAINER_SIZE / 2

  _.assignIn $scope,
    $scope.isIE = bowser.msie || bowser.msedge # detect Edge and IE 11+

    orbRetention: (d) ->
      if d.orb_type == "facet" then d.current_retention else d.average_current_retention

    # TODO: put this in the dom. There is some race condition right now and doesn't calculate height correctly.
    updateLine: (selection, class_name, level, level_slug, copy, animate) ->
      levelLine = selection.selectAll("." + class_name)

      return if !_.isEmpty(levelLine[0])
      initial_x = $scope.level_options.old_score || 0

      selection.append("svg").classed(class_name, true).classed(level_slug, true)
      levelLine = selection.select("." + class_name)
      levelLineHeight = selection.node().getBoundingClientRect().height - 40
      string = '0 0 100 ' + (levelLineHeight + 20)
      x_position = if initial_x > 0 then $scope.xScale(initial_x) - (SVG_WIDTH/2) else -100 #start off screen

      levelLine.attr
        width: SVG_WIDTH
        height: levelLineHeight + 20
        viewBox: string
        x: x_position
        y: 30 + MOBILE_TOP_PADDING

      levelLine.append("line").attr
        y1: 60
        y2: levelLineHeight - 80
        x1: SVG_WIDTH/2
        x2: SVG_WIDTH/2

      levelLine.append("line").attr
        y1: levelLineHeight + 10
        y2: levelLineHeight - 10
        x1: SVG_WIDTH/2
        x2: SVG_WIDTH/2

      levelLine.append("rect")
      levelLine.select("rect").attr
        width: 32
        height: 70
        rx: 5
        ry: 5
        x: (SVG_WIDTH/2) - (RECT_WIDTH/2)
        y: levelLineHeight - 80

      levelLine.append("text").text(copy).classed("set-level-label", true)
      levelLine.select("text").attr
        x: -levelLineHeight + 45
        y: 55
        'text-anchor': 'middle'
        transform: 'rotate(-90)'

      shape = levelLine.append("svg")

      shape.classed("memory-bank-goal-shape", true)
      shape.classed("transparent", true) # so you can see the orbs behind the shape
      shape.attr
        y: 20
        x: 23
      shape.append("path").attr
        d: "M30.4,39.9c-1.8,2.8-4.8,2.8-6.6,0L0.8,5.1C-1,2.3,0.3,0,3.7,0h46.6c3.4,0,4.7,2.3,2.9,5.1L30.4,39.9z"
        class: level_slug

      if class_name == "memory-bank--scoring-goal-line"
        star_shape = levelLine.append("svg")
        star_shape.attr
          id: 'memory-bank--mini-star'

        star_shape.classed("memory-bank-goal-shape", true)
        star_shape.attr
          x: STAR_X_OFFSET
          y: levelLineHeight - 100
          width: STAR_SIZE * STAR_INITIAL_SCALE
          height: STAR_SIZE * STAR_INITIAL_SCALE

        if $scope.isIE
          star_shape.classed("goal-mini-ie", false)
        else
          star_shape.classed("goal-reached", false).classed("goal-animating", false).classed("goal-mini", true)

        star_shape.append("polygon").attr
          points: "0,16.4 -16,25 -13,6.9 -26,-5.9 -8,-8.6 0,-25 8,-8.6 26,-5.9 13,6.9 16,25"
          class: level_slug

      levelLine.append("text").text($filter('levelRoundDown')(initial_x)).classed("current-set-level", true).classed(level_slug, true)
      levelLine.select(".current-set-level").attr
        x: (SVG_WIDTH/2)
        y: 40
        'text-anchor': 'middle'

      scoreText = levelLine.select(".current-set-level").node()
      scoreText.textContent = $filter('levelRoundDown')(level)

    updateGoalLine: (selection) ->
      $scope.updateLine(selection, "memory-bank--scoring-goal-line", $scope.level_options.scoring_goal, $scope.level_options.scoring_goal_slug, 'Set Goal', false)
      levelLine = selection.select(".memory-bank--scoring-goal-line")
      levelLine.attr
        x: $scope.xScale($scope.level_options.scoring_goal) - 50

    updateLevelLine: (selection) ->
      $scope.updateLine(selection, "memory-bank--level-line", ($scope.level_options.old_score || $scope.level_options.score), $scope.level_options.level_slug, 'Set Level', true)

    animateLevelLine: (selection) ->
      return if _.isUndefined($scope.level_options) || _.isNull($scope.level_options.score)
      initial_x = $scope.level_options.old_score || 0
      roundedScore = initial_x
      animateDuration = 1500
      intervalLength = 250
      level = $scope.level_options.score
      intervalChunk = (level - initial_x) / (animateDuration/intervalLength)
      levelLine = selection.select(".memory-bank--level-line")
      scoreText = levelLine.select(".current-set-level").node()

      if $scope.level_options.old_score
        interval = $interval( () ->
          return if roundedScore >= level
          roundedScore = roundedScore + intervalChunk
          scoreText.textContent = $filter('levelRoundDown')(roundedScore)
        , intervalLength)

        $timeout () ->
          clearInterval(interval)
          scoreText.textContent = $filter('levelRoundDown')(level)
        , 2500
      else if level
        scoreText.textContent = $filter('levelRoundDown')(level)

      levelLine.transition().delay(500).duration(animateDuration).attr(x: $scope.xScale(level) - (SVG_WIDTH/2)).ease('quad-out')

    animateGoalStar: (selection) ->
      animateMiniStarStartInMS = 1000
      miniStarAnimationLengthInMS = 2000
      starFinishedAnimateInMS = 2900

      star_shape = selection.select("#memory-bank--mini-star")
      star_shape
      .transition()
      .delay(animateMiniStarStartInMS)
      .duration(1)
      .attr(class: 'goal-animating memory-bank-goal-shape')
      .transition()
      .delay(animateMiniStarStartInMS)
      .duration(miniStarAnimationLengthInMS)
      .attr(y: STAR_Y_OFFSET, x: STAR_X_OFFSET)
      .transition(miniStarAnimationLengthInMS)
      .delay(starFinishedAnimateInMS)
      .duration(1)
      .attr(class: 'goal-expanded goal-animated memory-bank-goal-shape')
      .ease('quad-out')

      selection.select(".memory-bank--scoring-goal-line").select("line").transition().delay(starFinishedAnimateInMS).attr
        y1: STAR_SIZE

      $timeout () ->
        star_shape.classed("transparent", true)
      , starFinishedAnimateInMS

      triangle_shape = selection.select(".memory-bank--scoring-goal-line").select("svg")
      triangle_shape.transition().delay(starFinishedAnimateInMS).remove()

    updateGoalStar: (selection) ->
      star_shape = selection.select("#memory-bank--mini-star")
      star_shape.attr(y: STAR_Y_OFFSET, x: STAR_X_OFFSET, class: 'goal-expanded memory-bank-goal-shape transparent')
      triangle_shape = selection.select(".memory-bank--scoring-goal-line").select("svg")
      triangle_shape.remove()
      selection.select(".memory-bank--scoring-goal-line").select("line").attr
        y1: STAR_SIZE

    updateIEGoalStar: (selection) ->
      star_shape = selection.select("#memory-bank--mini-star")
      star_shape.attr(y: STAR_Y_OFFSET, x: STAR_X_OFFSET, class: 'goal-expanded memory-bank-goal-shape transparent')
      triangle_shape = selection.select(".memory-bank--scoring-goal-line").select("svg")
      triangle_shape.remove()
      star_shape.select("polygon").attr
        points: "0,16.4 -16,25 -13,6.9 -26,-5.9 -8,-8.6 0,-25 8,-8.6 26,-5.9 13,6.9 16,25"
      selection.select(".memory-bank--scoring-goal-line").select("line").attr
        y1: STAR_SIZE

    addGoalLines: (selection) ->
      return if _.isUndefined($scope.level_options)
      goal_achieved = ($scope.level_options.score - $scope.level_options.scoring_goal) > 0
      if $scope.level_options.scoring_goal && (_.last($scope.levels).intLevel + 1 > $scope.level_options.scoring_goal)
        $scope.updateGoalLine(selection)
      if $scope.level_options.score
        $scope.updateLevelLine(selection)

      if !_.isUndefined($scope.level_options.old_score) && goal_achieved
        $scope.updateGoalStar(selection)
      else if $scope.isIE && goal_achieved
        $scope.updateIEGoalStar(selection)
      # Don't know the progress in the home knowledge bank..we don't know the context of the group
      else if !$scope.isIE && (($scope.level_options.progress == 1) || (_.isUndefined($scope.level_options.progress) && goal_achieved))
        $scope.animateGoalStar(selection)
      return true

    updateAxis: (selection) ->

    updateScales: (svgDimensions, data, radius, levels, options) ->
      if $scope.isHC
        radius = BOX_SIZE / 2

      $scope.bb =
        left: LEFT_BORDER_WIDTH + radius
        right: svgDimensions.x - RIGHT_BORDER_WIDTH - radius
        top: HEADER_HEIGHT + radius + 1
        bottom: svgDimensions.y - radius - 1

      $scope.level_options = options
      $scope.levels = levels

      # round highest attained score to the next largest integer so that the highest
      # scored orb isn't always on the right edge. This also prevents the situation where
      # if the highest scored orb increased its score, it would make other orbs in its level
      # look like they lost permanence because the scale would expand
      max = Math.ceil(_.max(_.map(data, (d) -> d.score || d.level)) || 0)
      if levels.length > 0
        scoring_goal = options && options.scoring_goal
        lastIntLevel = _.last(levels).intLevel
        if Math.floor(scoring_goal) == lastIntLevel
          max = Math.max(scoring_goal || 0, max)
        else
          max = Math.max(lastIntLevel || 0, max)
        domain = _.map(levels, "minLevel").concat(max)
      else
        domain = [0,max]
      range = [$scope.bb.left]
      _.times levels.length, (i) ->
        range.push($scope.bb.left + ($scope.bb.right - $scope.bb.left) * (i + 1) / levels.length)

      continousXScale = d3.scale.linear().domain(domain).range(range)
      continousYScale = d3.scale.linear().domain([1,NEEDS_REVIEW_RETENTION,0]).range([$scope.bb.top, .5 * ($scope.bb.bottom + $scope.bb.top), $scope.bb.bottom])

      if $scope.isHC
        yDomain = [continousYScale.range()[0], _.last(continousYScale.range())]
        quantizedYScale = d3.scale.quantize().domain(yDomain).range(d3.range($scope.bb.top, $scope.bb.bottom, BOX_SIZE))
        xDomain = [continousXScale.range()[0], _.last(continousXScale.range())]
        quantizedXScale = d3.scale.quantize().domain(xDomain).range(d3.range($scope.bb.left, $scope.bb.right, BOX_SIZE))

        $scope.yScale = (d) ->
          quantizedYScale(continousYScale($scope.orbRetention(d)))
        $scope.xScale = (d) ->
          quantizedXScale(continousXScale(d.score || d.level))

        grid = {}
        for d in data
          x = $scope.xScale(d)
          y = $scope.yScale(d)
          grid[x] ||= {}
          grid[x][y] ||= []
          grid[x][y].push(d)

        $scope.data = _.flatten(_.map(grid, (col, x) ->
          _.map(col, (data, y) ->
            id: x + ":" + y
            orb_type: "heatmap"
            count: data.length
            data: data
            level_slug: data[0].level_slug
            target:
              x: parseInt(x)
              y: parseInt(y)
        )))

        max = _.max(_.map($scope.data, "count"))

        opacityScale = d3.scale.log().domain([1,max]).range([.2,1.0])
        $scope.opacityScale = (d) ->
          opacityScale(d.count)
      else
        $scope.xScale = continousXScale
        $scope.yScale = continousYScale


    calculateFinalPosition: (d) ->
      x: $scope.xScale(d.score || d.level)
      y: $scope.yScale($scope.orbRetention(d))

    setSourcePosition: (parent, preload) ->
      (selection) ->
        selection.each (d) ->
          # preload flag is for setting the initial positions of the orbs
          # instead of animating from off screen, so that pre/post study screens
          # show the transition of memory orbs as a result of the study session
          if $scope.isHC || preload
            finalPosition = $scope.calculateFinalPosition(d)
            d.x = finalPosition.x
            d.y = finalPosition.y
          else
            if parent && parent.hasOwnProperty("x")
              d.x = parent.x
              d.y = parent.y
            else
              d.x = - .5 * LEFT_BORDER_WIDTH
              d.y = $scope.yScale(.5)

    setTargetPosition: (selection) ->
      unless $scope.isHC
        selection.each (d) ->
          d.target = $scope.calculateFinalPosition(d)

    # For HC only
    createNode: (selection) ->
      selection.classed "progress-heatmap", true
      selection.append "title"
      .text (d) -> d.count

      selection.append "rect"
      .attr
          x: - BOX_SIZE / 2
          y: - BOX_SIZE / 2
          width: BOX_SIZE
          height: BOX_SIZE

    hcAnimate: (selection) ->
      selection.each (d) ->
        d.delay = Math.random() * 400

      selection.attr
        transform: (d) ->
          "translate(" + [d.x, d.y] + ") scale(0)"
      .style("fill-opacity", 0)

      selection.transition()
      .delay (d) ->
          d.delay
      .duration(600)
      .style("fill-opacity", $scope.opacityScale)
      .attr
          transform: (d) ->
            "translate(" + [d.x, d.y] + ") scale(1)"

  $scope
])
