memoryBank.service("totalTimeVisual", ['$rootScope', 'timeHelper', 'hcHelper', ($rootScope, timeHelper, hcHelper) ->
  (field) ->
    $scope = $rootScope.$new()

    window.debug = window.debug || {}
    window.debug["totalTimeVisual"] = $scope

    HEADER_HEIGHT = 60
    AXIS_TICKS = 6
    MIN_STUDY_MSEC = 60*1000
    TRANSITION_DURATION = 1000
    BOX_SIZE = 20

    _.assignIn $scope,
      accessor: (d) ->
        d[field] || 0

      updateAxis: (selection) ->
        tickSize = timeHelper.round($scope.xScale.domain()[1] / AXIS_TICKS)
        tickValues = d3.range(tickSize, $scope.xScale.domain()[1], tickSize)

        console.log "tickValues:"
        console.log tickValues

        xAxis = d3.svg.axis()
        .scale($scope.xScale)
        .orient("top")
        .tickValues(tickValues)
        .tickFormat(timeHelper.msecToString)

        selection.select(".axis")
        .attr
          transform: "translate(" + [0, $scope.bb.top / 2] + ")"
        .transition()
        .duration(TRANSITION_DURATION)
        .call(xAxis)

        xGrid = d3.svg.axis()
        .scale($scope.xScale)
        .tickValues(tickValues)
        .orient("bottom")
        .tickSize(-($scope.bb.bottom - $scope.bb.top + 70), 0, 0)
        .tickFormat("")

        selection.select(".grid")
        .attr
          transform: "translate(" + [0, $scope.bb.bottom + 25] + ")"
        .transition()
        .duration(TRANSITION_DURATION)
        .call(xGrid)

      updateScales: (svgDimensions, data, radius, levels, options) ->
        if $scope.isHC
          radius = BOX_SIZE / 2

        xPadding = 50
        xPadding += ((svgDimensions.x - 2*xPadding) % 2*radius) / 2

        $scope.bb =
          top: HEADER_HEIGHT + radius
          bottom: svgDimensions.y - radius
          left: xPadding
          right: svgDimensions.x - xPadding
          padding: radius

        times = _.map data,$scope.accessor
        domain = [
          Math.min(d3.min(times) || 0, 0)
          Math.max(d3.max(times) || 0, MIN_STUDY_MSEC)
        ]

        $scope.xScale = d3.scale.quantize()
        .domain(domain)
        .range(d3.range($scope.bb.left, $scope.bb.right, 2*radius))

        if $scope.isHC
          aggregate = hcHelper.aggregate(data, (d) -> $scope.xScale($scope.accessor(d)))
          $scope.sums = aggregate.sums

          $scope.yScale = d3.scale.linear().domain([0, aggregate.max]).range([$scope.bb.bottom + radius, $scope.bb.top - radius])

          $scope.data = _.sortBy(_.flatten(_.map(aggregate.grid, (col, x) ->
            _.map col, (data, level_slug) ->
              id: x + ":" + level_slug
              orb_type: "bargraph"
              count: data.length
              data: data
              level_slug: level_slug
              target:
                x: parseInt(x)
                y: $scope.yScale(data.length + $scope.sums[x][level_slug])
          )),"level_slug").reverse()
        else
          bins = _.groupBy data, (d) -> $scope.xScale($scope.accessor(d))

          $scope.yScale = (d) ->
            return $scope.bb.bottom if !d

            bin = bins[$scope.xScale($scope.accessor(d))]
            index = _.indexOf(bin, d)
            count = bin.length
            offset = radius * 2

            if offset * count > ($scope.bb.bottom - $scope.bb.top)
              # Squished together placement
              pos = ($scope.bb.bottom - $scope.bb.top)/(count - 1)
            else
              # Placement at ideal offset
              pos = offset

            $scope.bb.bottom - pos*index

      setSourcePosition: (parent) ->
        (selection) ->
          selection.each (d) ->
            if $scope.isHC
              d.x = d.target.x
              d.y = d.target.y
            else
              if parent
                d.x = parent.x
                d.y = parent.y
              else
                d.x = $scope.xScale(0)
                d.y = $scope.yScale(0)

      setTargetPosition: (selection) ->
        unless $scope.isHC
          selection.each (d) ->
            d.target =
              x: $scope.xScale($scope.accessor(d))
              y: $scope.yScale(d)

      shouldCollide: (a, b) ->
        false

      collisionForce: (l, r, alpha) ->
        0

    # For HC visuals ONLY
      createNode: (selection) ->
        selection.classed "timeline-bargraph", true
        selection.append "title"
        .text (d) -> d.count + " in " + d.level_slug

        selection.append "rect"
        .attr
          x: - BOX_SIZE / 2
          y: 0
          width: BOX_SIZE
          height: (d) -> $scope.yScale($scope.sums[d.target.x][d.level_slug]) - $scope.yScale(d.count + $scope.sums[d.target.x][d.level_slug])

      hcAnimate: (selection) ->
        delayMult = 100

        selection.attr
          transform: (d) ->
            "translate(" + [d.x, $scope.yScale(0)] + ") scale(1 0)"
        .style("fill-opacity", 0)

        selection.transition()
        .delay (d) ->
          switch d.level_slug
            when "mastered"
              delayMult * 4
            when "level_4"
              delayMult * 3
            when "level_3"
              delayMult * 2
            when "level_2"
              delayMult * 1
            else
              delayMult * 0
        .duration(600)
        .style("fill-opacity", 1)
        .attr
          transform: (d) ->
            "translate(" + [d.x, d.y] + ") scale(1 1)"

    $scope
])
