siteNavigationApp.service('productService', ['$rootScope', 'DebugHelper', '$q', ($rootScope, DebugHelper, $q) ->
  $scope = $rootScope.$new()

  DebugHelper.register("productService", $scope)

  DEFAULT_PRODUCT = "learn"

  tryResolve = () ->
    if $scope.currentProduct && $scope.deferred && !$scope.resolved
      $scope.deferred.resolve($scope.currentProduct)
      $scope.resolved = true

  _.assignIn $scope,
    setCurrentProduct: (product) ->
      $scope.currentProduct = product || DEFAULT_PRODUCT
      tryResolve()

    initialize: () ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        tryResolve()

      $scope.deferred.promise

  $scope
])
