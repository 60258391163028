ceregoCommon.factory('V3SessionResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_sessions_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_sessions_path()
    destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_sessions_path()
])
