signupApp.controller('upgradeProCtrl', ['$scope', '$routeParams', '$filter', '$window', 'UserManager', 'V3PartnersResource', 'ProPilotOffersResource', 'StripeService', 'myPartnersService', 'V3ModelHelper', ($scope, $routeParams, $filter, $window, UserManager, V3PartnersResource, ProPilotOffersResource, StripeService, myPartnersService, V3ModelHelper) ->
  window.debug = window.debug || {}
  window.debug["upgradeProCtrl"] = $scope

  _.assignIn $scope,
    loading: true
    stateUI: {}

    selectPlan: (plan) ->
      $scope.currentPlan = plan

    stripeHandler: ->
      stripeOptions =
        email: UserManager.currentUser.attributes.email
        panelLabel: $filter('translate')('js.upgrade.pro.pay')
        name: $filter('translate')('js.upgrade.pro.name')
        description: $filter('translate')('js.upgrade.pro.description')
        amount: $scope.cost

      StripeService.configureHandler(stripeOptions).then (token) ->
        ProPilotOffersResource.update
          id: $routeParams.offer_token
          token: token.id
          email: token.email
        , (success) ->
          V3PartnersResource.get id: success.data.attributes['partner-id']
          , (success) ->
            myPartnersService.chooseProductPartner(V3ModelHelper.parse(success))
            # need to hard reload here to switch to pro
            $window.location.href = Packs.iKnowRoutes.v3_courses_path()

  myPartnersService.initialize().then () ->
    ProPilotOffersResource.get id: $routeParams.offer_token
    , (success) ->
      $scope.pilotOffer = success.data
      $scope.stateUI.customCopy = $scope.pilotOffer.attributes['custom-copy'] || ""
      $scope.stateUI.partnerName = $scope.pilotOffer.attributes['partner-name']
      $scope.cost = $scope.pilotOffer.attributes['cost']
      $scope.loading = false
    , (failure) ->
      $window.location.href = "/404"
])
