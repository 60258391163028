ceregoLayoutApp.factory("EditPartnersService", ['$rootScope', '$routeParams', '$q', 'DebugHelper', 'V3PartnersResource', 'V3ModelHelper', ($rootScope, $routeParams, $q, DebugHelper, V3PartnersResource, V3ModelHelper) ->
  $scope = $rootScope.$new()

  DebugHelper.register("EditPartnersService", $scope)

  _.assignIn $scope,
    partner: {}
    deferred: {}

    loadPartner: ->
      if !$scope.deferred.partner
        deferred = $q.defer()
        $scope.deferred.partner = deferred
        V3PartnersResource.get
          id: $routeParams.partner_id
          include: 'logo_image,ios_brand_image,icon_image,image,background_image,partner_domains,partner_library,partner_library.image'
        , (success) ->
          $scope.partner = V3ModelHelper.parse(success)

          deferred.resolve($scope.partner)
        , deferred.reject

      $scope.deferred.partner.promise

    updatePartner: (params, doSuccess, doError) ->
      params.include = 'logo_image,ios_brand_image,icon_image,image,background_image,partner_domains,partner_library,partner_library.image'
      V3PartnersResource.update id: $scope.partner.id
      , params
      , (success) ->
        $scope.partner = V3ModelHelper.parse(success)
        doSuccess(success)
      , doError

  $scope.$on "$routeChangeStart", (current, routes) ->
    if $scope.partner.attributes.slug != routes.params.partnerId && $scope.partner.id != parseInt(routes.params.partnerId)
      $scope.deferred.partner = null

  $scope
])
