siteNavigationApp.factory('PartnersResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_my_partner_path()
    destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_my_partner_path()
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_partner_path(":id")
      params:
        id: "@id"
    save:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_partner_path(":id")
      params:
        id: "@id"
    sets:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_partner_sets_path(":partner_id")
      params:
        partner_id: "@partner_id"
    series:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_partner_series_index_path(":partner_id")
      params:
        partner_id: "@partner_id"
    series_save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_partner_series_index_path(":partner_id")
      params:
        partner_id: "@partner_id"
    my_partners:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_partners_path()
])
