siteNavigationApp.factory('V3DeloitteAutoLogInResource', ['$resource', ($resource) ->
  $resource "", {},
    learn:
      method: 'POST'
      isArray: false
      url: APIRoutes.learn_v3_deloitte_autologin_sessions_path()
    courses:
      method: 'POST'
      isArray: false
      url: APIRoutes.courses_v3_deloitte_autologin_sessions_path()
])
