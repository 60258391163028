siteNavigationApp.factory("ContextService", ['$rootScope', '$routeParams', '$location', '$q', '$window', 'DebugHelper', 'SetsResource', 'SeriesResource', 'LtiService', 'V3CoursesResource', (
  $rootScope, $routeParams, $location, $q, $window, DebugHelper, SetsResource, SeriesResource, LtiService, V3CoursesResource) ->
  $scope = $rootScope.$new()

  DebugHelper.register("ContextService", $scope)

  _.assignIn $scope,
    hasLoadedAtLeastOnce: false
    fromPreview: false

    setContext: (module_type, module_id) ->
      $scope.contextId = module_id
      $scope.contextType = module_type

    loadContext: ->
      LtiService.loadGroup().then () ->
        unless $scope.deferred
          $scope.deferred = $q.defer()

          onError = (error) ->
            if error.status == 403
              #If we have an LTI context, we may not want to redirect on 403, we may want to collect payment information,
              #validate group user and try again; let caller handle in fail callback
              if !LtiService.paymentRequired
                $window.location.href = Packs.iKnowRoutes.v3_signin_path()
              else
                $scope.deferred.reject(error)
            else
              $scope.deferred.reject(error)

          switch $scope.contextType
            when "set"
              SetsResource.get id: $scope.contextId, (success) ->
                $scope.context = success.response
                $scope.deferred.resolve($scope.context)
              , onError
            when "series"
              SeriesResource.get id: $scope.contextId, (success) ->
                $scope.context = success.response
                $scope.deferred.resolve($scope.context)
              , onError
            when "courses"
              V3CoursesResource.get id: $scope.contextId, (success) ->
                $scope.context =
                  module_type: "courses"
                  id: $scope.contextId
                  name: success.data.attributes.name
                $scope.deferred.resolve($scope.context)
              , onError
            else
              $scope.context =
                name: "Study All"
                module_type: "study_all"
              $scope.deferred.resolve($scope.context)

        $scope.deferred.promise

    hasOverride: (override) ->
      $scope.context && ($scope.context.overrides || {})[override]

  dataContextId = $location.search().context_id
  dataContextType = $location.search().context_type

  if dataContextId && dataContextType
    $scope.setContext(dataContextType, dataContextId)
  else if $routeParams.setId
    $scope.setContext("set", $routeParams.setId)
  else if $routeParams.seriesId
    $scope.setContext("series", $routeParams.seriesId)
  else if $routeParams.contextType == "courses"
    $scope.setContext("courses", $routeParams.contextId)
  else
    $scope.setContext("study_all", null)

  $scope
])
