memoryBank.service("hcHelper", ['$rootScope', 'MemoryBankLevelsService', ($rootScope, MemoryBankLevelsService) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["hcHelper"] = $scope

  _.assignIn $scope,
    threshold: (isFacet) ->
      if isFacet then 600 else 300

    aggregate: (data, aggregatorFn, reverse) ->
      bins = _.groupBy(data, aggregatorFn)
      max = _.max(_.map(bins, (bin) -> bin.length))
      grid = {}
      sums = {}
      for x, col of bins
        grid[x] = _.groupBy(col,"level_slug")
        sums[x] = {}
        sum = 0
        levels = _.map(MemoryBankLevelsService.levels, "slug")
        if reverse
          levels = levels.reverse()
        for level_slug in levels
          sums[x][level_slug] = sum
          sum += (grid[x][level_slug] || []).length

      {
        max: max
        sums: sums
        grid: grid
      }
])
