ceregoLayoutApp.directive('cgModuleDropDown', [->
  scope:
    module: "=cgModuleDropDown"

  templateUrl: Packs.iKnowRoutes.v2_template_path("module_drop_down")

  controller: ['$window', '$scope', '$location', '$timeout', 'DebugHelper', 'myPartnersService', 'ModalService', 'SetsResource', 'SeriesResource', 'MySetsResource', 'MySeriesResource', 'V3SetClonesResource', 'SetExportService', 'UserManager', 'SiteNavigationService', 'setLearnURLService', ($window, $scope, $location, $timeout, DebugHelper, myPartnersService, ModalService, SetsResource, SeriesResource, MySetsResource, MySeriesResource, V3SetClonesResource, SetExportService, UserManager, SiteNavigationService, setLearnURLService) ->
    DebugHelper.register("cgModuleDropDown", $scope)

    _.assignIn $scope, {
      editModulePath: ""
      export_status: 'not_started'
      dirty: false
      routes: Packs.iKnowRoutes
      SiteNavigationService: SiteNavigationService
      ModalService: ModalService
      stateUI :
        showExport: false
        showDelete: false
        showCreatorTool: false
        showAddToSeries: false
        cloneOptions: {}

      getPreviewUrl: ->
        setLearnURLService.getUrl({id: $scope.module.id, version: $scope.module.learn_version}, { studyType: 'all', preview: true, debug: true, returnURL: ($window.location.pathname) })

      shouldShowCreatorTools: ->
        return false unless SiteNavigationService.isCreate()
        if $scope.module.partner_id
          $scope.partner && ($scope.module.partner_id == $scope.partner.id)
        else
          $scope.module.permissions && $scope.module.permissions.editable


      onCloneSetSuccess: (success) ->
        console.log(success)
        setClone = success
        if setClone.status == "completed"
          $window.location.href = Packs.iKnowRoutes.v2_create_set_path(success.target_set_id)
        else
          $timeout ->
            V3SetClonesResource.get id: success.id
            , $scope.onCloneSetSuccess
            , (error) ->
              console.error error
          , 2000

      cloneSet: (cloneGoalType) ->
        params = {}
        if cloneGoalType
          params.goal_type = cloneGoalType
        if $scope.partner
          params.partner_id = $scope.partner.id

        $scope.$emit "moduleDropDown:remixProcessing"

        V3SetClonesResource.create set_id: $scope.module.id
          , data: { attributes: params }
          , $scope.onCloneSetSuccess
          , (error) ->
            $scope.$emit "moduleDropDown:processingError"
            console.error error

      isScorm: ->
        !!$scope.module.scorm_package_id

      shouldShowAddToSeries: ->
        $scope.partner?.meta['can-manage-content'] && $scope.module.module_type == 'set' && !$scope.isScorm() && $scope.isV3()

      isV3: ->
        $scope.partner?.attributes?['partner-settings']['learn-version'] < 4

      getCloneOptions: ->
        if $scope.partner?.meta["can-manage-content"] && $scope.module.module_type == 'set'
          {
            'showCloneOption': !$scope.isScorm(),
            'showCloneAsAssessmentOption': $scope.module.goal_type == 'set' && !$scope.isScorm() && !$scope.isV3(),
            'showCloneAsSetOption': $scope.module.goal_type == 'assessment',
          }

      shouldShowDelete: ->
        if $scope.module.partner_id
          ($scope.partner?.id == $scope.module.partner_id) && ($scope.partner?.meta["can-manage-content"] || $scope.module.permissions.is_owner)
        else
          $scope.module.permissions && $scope.module.permissions.is_owner

      shouldShowExport: ->
        $scope.module.partner_id && $scope.module.module_type == 'set' && !$scope.isScorm() &&
          ($scope.partner?.id == $scope.module.partner_id) && $scope.partner?.meta["can-manage-content"] && $scope.module.learn_version < 4

      resource: ->
        if $scope.module.module_type == "set"
          MySetsResource
        else
          MySeriesResource

      deleteModule: ->
        module_type = $scope.module.module_type

        if $window.confirm("Are you sure you want to delete this " + module_type + "?")
          resource = if module_type == 'set' then SetsResource else SeriesResource
          resource.delete { id: $scope.module.id }
          , {}
          , (result) ->
            $scope.$emit "moduleDropDown:delete", $scope.module.id, $scope.module.module_type
          , (error) ->
            console.error error

      exportCSV: ->
        $scope.export_status = 'pending'
        SetExportService.export($scope.module).then (setExport) ->
          $scope.setExport = setExport
          $scope.export_status = 'completed'

      addToSchedule: ->
        $scope.module.settings.library_subscription_state = 0
        $scope.resource().addToSchedule id: $scope.module.id

      removeFromSchedule: ->
        $scope.module.settings.library_subscription_state = 1
        $scope.resource().removeFromSchedule id: $scope.module.id

      addToLibrary: ->
        $scope.module.settings.library_subscription_state = 1
        $scope.resource().addToLibrary id: $scope.module.id

      removeFromLibrary: ->
        $scope.module.settings.library_subscription_state = 2
        $scope.resource().removeFromLibrary id: $scope.module.id

      initializeStateUI: ->
        $scope.stateUI.showCreatorTool = $scope.shouldShowCreatorTools()
        $scope.stateUI.showExport = $scope.shouldShowExport()
        $scope.stateUI.showDelete = $scope.shouldShowDelete()
        $scope.stateUI.showAddToSeries = $scope.shouldShowAddToSeries()
        $scope.stateUI.cloneOptions = $scope.getCloneOptions()

      initializeModuleAttributes: () ->
        if !$scope.module.settings
          $scope.module.settings = {}
        if $scope.module.id
          $scope.initializeStateUI()
          if $scope.module.module_type == "set"
            $scope.editModulePath = Packs.iKnowRoutes.edit_details_v2_create_set_path($scope.module.id)
          else
            $scope.editModulePath = Packs.iKnowRoutes.edit_details_v2_create_series_path($scope.module.id)
    }

    $scope.$watch "module", ->
      if $scope.module
        UserManager.loadUser().then (user) ->
          $scope.currentUser = user
          myPartnersService.initialize().then () ->
            $scope.partner = myPartnersService.currentProductPartner
            $scope.initializeModuleAttributes()

    # This is admin only, but safe since API will also run a permission check
    $scope.$watch "module.is_featured", (newVal, oldVal) ->
      if !_.isUndefined(oldVal) && newVal != oldVal
        $scope.dirty = true
        resource = if $scope.module.module_type == 'set' then SetsResource else SeriesResource
        resource.update { id: $scope.module.id }
        , _.pick($scope.module, ["is_featured"])
        , (success) ->
          $scope.dirty = false
  ]
])
