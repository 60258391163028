memoryBank.directive('memoryBankDemo', [->
  restrict : 'E'

  templateUrl: Packs.iKnowRoutes.memory_bank_v2_templates_path("memory_bank")

  controller: ['$scope', 'MemoryBankAssetHelper', 'MemoryBankLevelsService', ($scope, MemoryBankAssetHelper, MemoryBankLevelsService) ->
    window.debug = window.debug || {}
    window.debug["memoryBankDemoCtrl"] = $scope

    _.assignIn $scope,
      dirty: false
      showHeader: false
      hideFooter: true
      unlockedLevels: []
      preload: true
      currentMode: "Progress"
      currentTheme: "night"
      isDemo: true
      currentHelpMode:
        slug: 'meaning'
      orbsByLevel:
        level_new: []
        level_building: []
        level_1: []
        level_2: []
        level_3: []
        level_4: []
        mastered: []

      helpLevels: [
        name: "js.memory_bank.help_levels.level_new.name"
        class: "level_new"
      ,
        name: "js.memory_bank.help_levels.level_building.name"
        class: "level_building"
      ,
        name: "js.memory_bank.help_levels.level_1.name"
        class: "level_1"
        duration: "js.memory_bank.help_levels.level_1.duration"
        frequency: "js.memory_bank.help_levels.level_1.frequency"
        time: "js.memory_bank.help_levels.level_1.time"
        detail: "quickly forgotten"
      ,
        name: "js.memory_bank.help_levels.level_2.name"
        class: "level_2"
        duration: "js.memory_bank.help_levels.level_2.duration"
        frequency: "js.memory_bank.help_levels.level_2.frequency"
        time: "js.memory_bank.help_levels.level_2.time"
        study_time_item: "js.memory_bank.help_levels.level_2.study_time_item"
        study_time_set: "js.memory_bank.help_levels.level_2.study_time_set"
        study_duration: "js.memory_bank.help_levels.level_2.study_duration"
      ,
        name: "js.memory_bank.help_levels.level_3.name"
        class: "level_3"
        duration: "js.memory_bank.help_levels.level_3.duration"
        frequency: "js.memory_bank.help_levels.level_3.frequency"
        time: "js.memory_bank.help_levels.level_3.time"
        study_time_item: "js.memory_bank.help_levels.level_3.study_time_item"
        study_time_set: "js.memory_bank.help_levels.level_3.study_time_set"
        study_duration: "js.memory_bank.help_levels.level_3.study_duration"
      ,
        name: "js.memory_bank.help_levels.level_4.name"
        class: "level_4"
        duration: "js.memory_bank.help_levels.level_4.name"
        frequency: "js.memory_bank.help_levels.level_4.frequency"
        time: "js.memory_bank.help_levels.level_4.time"
        study_time_item: "js.memory_bank.help_levels.level_4.study_time_item"
        study_time_set: "js.memory_bank.help_levels.level_4.study_time_set"
        study_duration: "js.memory_bank.help_levels.level_4.study_duration"
      ,
        name: "js.memory_bank.help_levels.level_master.name"
        class: "mastered"
        duration: "js.memory_bank.help_levels.level_master.duration"
        frequency: "js.memory_bank.help_levels.level_master.frequency"
        time: ""
        detail: "in long term memory"
      ]

      allLevels: [
        name: "js.memory_bank.level_new.name"
        slug: "level_new"
        locked: false
        minimumReviewInterval: MemoryBankLevelsService.levels[1].minimumReviewInterval
        maximumReviewInterval: MemoryBankLevelsService.levels[1].maximumReviewInterval
        minLevel: MemoryBankLevelsService.levels[1].minLevel
      ,
        name: "js.memory_bank.level_building.name"
        slug: "level_building"
        locked: false
        minimumReviewInterval: MemoryBankLevelsService.levels[2].minimumReviewInterval
        maximumReviewInterval: MemoryBankLevelsService.levels[2].maximumReviewInterval
        minLevel: MemoryBankLevelsService.levels[2].minLevel
      ,
        name: "Level 1"
        slug: "level_1"
        locked: false
        minimumReviewInterval: MemoryBankLevelsService.levels[3].minimumReviewInterval
        maximumReviewInterval: MemoryBankLevelsService.levels[3].maximumReviewInterval
        minLevel: MemoryBankLevelsService.levels[3].minLevel
      ,
        name: "Level 2"
        slug: "level_2"
        locked: true
        minimumReviewInterval: MemoryBankLevelsService.levels[4].minimumReviewInterval
        maximumReviewInterval: MemoryBankLevelsService.levels[4].maximumReviewInterval
        minLevel: MemoryBankLevelsService.levels[4].minLevel
      ,
        name: "Level 3"
        slug: "level_3"
        locked: true
        minimumReviewInterval: MemoryBankLevelsService.levels[5].minimumReviewInterval
        maximumReviewInterval: MemoryBankLevelsService.levels[5].maximumReviewInterval
        minLevel: MemoryBankLevelsService.levels[5].minLevel
      ,
        name: "Level 4"
        slug: "level_4"
        locked: true
        minimumReviewInterval: MemoryBankLevelsService.levels[6].minimumReviewInterval
        maximumReviewInterval: MemoryBankLevelsService.levels[6].maximumReviewInterval
        minLevel: MemoryBankLevelsService.levels[6].minLevel
      ,
        name: "Mastery Zone"
        slug: "mastered"
        locked: true
        minimumReviewInterval: MemoryBankLevelsService.levels[7].minimumReviewInterval
        minLevel: MemoryBankLevelsService.levels[7].minLevel
      ]

      levelClasses: (level) ->
        classes = [level.slug]
        classes.push("locked") if level.locked
        classes

      initializeDemo: ->
        $scope.levels = $scope.allLevels
        orbs = [{ learning_engine_guid: 1, pulse: false, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 2, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 3, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 4, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 5, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 6, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 8, pulse: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
        { learning_engine_guid: 7, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95}]

        $scope.orbsByLevel.level_new = orbs.slice(0, 7)
        $scope.unlockedLevels = $scope.levels.slice(0,3)
        $scope.isDemo = true
        $scope.orbs = orbs
        angular.element("memory-bank-demo").addClass("theme-night")

    $scope.$on "learnMoreMemoryBank:setStep", (event, step) ->
      $scope.currentMode = "Progress"
      switch step
        when 1
          $scope.initializeDemo()
        when 2
          $scope.levels = $scope.levels.slice(0,3)
          $scope.preload = false
          angular.element(".learn-app--membank-container").width("80%")
          angular.element(".learn--app-blurb-aminate").slideUp(->
            $scope.orbs = [{ learning_engine_guid: 1, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.95},
            { learning_engine_guid: 2,  disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.25, current_retention: 0.91},
            { learning_engine_guid: 3,  disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.15, current_retention: 0.945},
            { learning_engine_guid: 4,  disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.18, current_retention: 0.955},
            { learning_engine_guid: 5,  disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.29, current_retention: 0.97},
            { learning_engine_guid: 6,  disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.02, current_retention: 0.5},
            { learning_engine_guid: 9,  disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.22, current_retention: 0.7}]
            $scope.orbsByLevel.level_new = $scope.orbs
            angular.element(".learn-app--membank-explain").show()
          )
          return true
        when 3
          $scope.orbs = [{ learning_engine_guid: 1, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.4},
          { learning_engine_guid: 2, disabled: true,  orb_type: "facet", level_slug: "level_new", score: 0.25, current_retention: 0.91},
          { learning_engine_guid: 3, disabled: true,  orb_type: "facet", level_slug: "level_new", score: 0.15, current_retention: 0.945},
          { learning_engine_guid: 4, disabled: true,  orb_type: "facet", level_slug: "level_new", score: 0.18, current_retention: 0.955},
          { learning_engine_guid: 5, disabled: true,  orb_type: "facet", level_slug: "level_new", score: 0.29, current_retention: 0.97},
          { learning_engine_guid: 6, disabled: true,  orb_type: "facet", level_slug: "level_new", score: 0.02, current_retention: 0.5},
          { learning_engine_guid: 9, disabled: true,  orb_type: "facet", level_slug: "level_new", score: 0.22, current_retention: 0.7}]
          $scope.orbsByLevel.level_new = $scope.orbs
        when 4
          $scope.orbs = [{ learning_engine_guid: 1, orb_type: "facet", level_slug: "level_new", score: 0.25, current_retention: 0.975},
          { learning_engine_guid: 2, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.25, current_retention: 0.91},
          { learning_engine_guid: 3, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.10, current_retention: 0.945},
          { learning_engine_guid: 4, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.15, current_retention: 0.955},
          { learning_engine_guid: 5, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.29, current_retention: 0.97},
          { learning_engine_guid: 6, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.02, current_retention: 0.5},
          { learning_engine_guid: 9, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.22, current_retention: 0.7}]
          $scope.orbsByLevel.level_new = $scope.orbs
        when 5
          $scope.orbs = [{ learning_engine_guid: 1, orb_type: "facet", level_slug: "level_building", score: 0.35, current_retention: 0.99},
          { learning_engine_guid: 2, disabled: true, orb_type: "facet", level_slug: "level_building", score: 0.6, current_retention: 0.91},
          { learning_engine_guid: 3, disabled: true, orb_type: "facet", level_slug: "level_building", score: 0.85, current_retention: 0.945},
          { learning_engine_guid: 4, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.15, current_retention: 0.955},
          { learning_engine_guid: 5, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.29, current_retention: 0.97},
          { learning_engine_guid: 6, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.02, current_retention: 0.5},
          { learning_engine_guid: 9, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.22, current_retention: 0.7}]
          $scope.orbsByLevel.level_building = $scope.orbs.slice(0,3)
          $scope.orbsByLevel.level_new = $scope.orbs.slice(3,7)
        when 6
          $scope.orbs = [{ learning_engine_guid: 1, orb_type: "facet", level_slug: "level_new", score: 0.25, current_retention: 0.99},
          { learning_engine_guid: 2, disabled: true, orb_type: "facet", level_slug: "level_building", score: 0.6, current_retention: 0.91},
          { learning_engine_guid: 3, disabled: true, orb_type: "facet", level_slug: "level_building", score: 0.85, current_retention: 0.945},
          { learning_engine_guid: 4, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.15, current_retention: 0.955},
          { learning_engine_guid: 5, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.29, current_retention: 0.97},
          { learning_engine_guid: 6, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.02, current_retention: 0.5},
          { learning_engine_guid: 9, disabled: true, orb_type: "facet", level_slug: "level_new", score: 0.22, current_retention: 0.7}]
          $scope.orbsByLevel.level_building = $scope.orbs.slice(1,3)
          $scope.orbsByLevel.level_new = $scope.orbs.slice(3,7).concat($scope.orbs[0])
        when 7
          $scope.currentMode = "About"
  ]
])
