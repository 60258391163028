require('../../v3/modules/common/resources/v3-session-resource.js')

window.siteNavigationApp = angular.module("cerego.navigations", [
  "LocalStorageModule"
  "ngResource"
  "cerego.common"
])

# replace these with explicit import
req = require.context('./site-navigation/resources', false, /\.js$/)
req.keys().forEach(req)

require('./site-navigation/context-service.js')
require('./site-navigation/course-context-service.js')
require('./site-navigation/my_partners_service.js')
require('./site-navigation/product_service.js')
require('./site-navigation/site-navigation-service.js')
require('./site-navigation/user-manager.js')

# replace these with explicit import
req = require.context('./site-navigation/controllers', false)
req.keys().forEach(req)
req = require.context('./site-navigation/directives', false)
req.keys().forEach(req)
