ceregoLayoutApp.controller('ltiConfirmCtrl', ['$scope', '$window', 'DebugHelper', 'V3LtiConfirmationMailerResource', '$location', ($scope, $window, DebugHelper, V3LtiConfirmationMailerResource, $location) ->
  DebugHelper.register("ltiConfirmCtrl", $scope)

  _.assignIn $scope,
    uiState:
      state: "sendEmail"
    sendEmail: () ->
      V3LtiConfirmationMailerResource.create
        client_id: $scope.client_id
        external_user_id: $scope.external_user_id
      , (success) ->
        $scope.uiState.state = "submitToken"
      , (error) ->
        # TODO show some kind of error message
        DebugHelper.logError(error, "Error sending token mailer to " + $scope.email_address + " for Oauth2Client " + $scope.client_id)

    submitToken: (e) ->
      e.preventDefault()
      document.getElementById("ltiform").submit()

    isValid: () ->
      $scope.formData['lti_confirmation_token']?.length > 0

    buildFormData: () ->

      searchParams = $location.search()

      $scope.email_address = searchParams['email_address']
      $scope.request_path = searchParams['request_path']
      $scope.client_id = searchParams['client_id']
      $scope.external_user_id = searchParams['external_user_id']

      # add check for client, add client to form data
      if $scope.email_address && $scope.request_path && $scope.client_id
        $scope.formData = new FormData()
        $scope.formData.url = $scope.request_path

  $scope.buildFormData()

])
