siteNavigationApp.factory('V3PartnersResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partners_path()
    update:
      method: 'PATCH'
      isArray: false
      url: APIRoutes.v3_partner_path(":id")
      params:
        id: "@id"
    destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_partner_path(":id")
      params:
        id: "@id"
    my_partners:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_my_partners_path()
])
