ceregoLayoutApp.controller('partnerNotificationsCtrl', ['$scope', '$location', 'myPartnersService', 'PartnersResource', 'V2GroupContextService', 'SubscriptionsResource', ($scope, $location, myPartnersService, PartnersResource, V2GroupContextService, SubscriptionsResource) ->
  window.debug = window.debug || {}
  window.debug["partnerNotificationCtrl"] = $scope

  _.assignIn $scope,
    routes: Packs.iKnowRoutes

    toggleNotification: (key) ->
      $scope.subscriptions.email[key] = !$scope.subscriptions.email[key]
      params = $scope.subscriptions
      params.partner_id = $scope.partner.id
      SubscriptionsResource.update params

  # FIXME: THIS SHOULD SET PARTNER CONTEXT FROM URL
  V2GroupContextService.initialize().then () ->
    $scope.partner = myPartnersService.currentProductPartner
    if $scope.partner
      if !$scope.partner.meta.role #update in case their cache is not cleared and they do not get new role
        PartnersResource.get
          id: $scope.partner.id
        , (success) ->
          _.assignIn($scope.partner,success.response)
    else
      $location.url Packs.iKnowRoutes.v3_courses_path()
    SubscriptionsResource.query {}
    , (success) ->
      $scope.subscriptions = success.response.partner_email_settings.filter (subscription) ->
        subscription.partner_id = $scope.partner.id
      $scope.subscriptions = $scope.subscriptions[0]
      $scope.subscriptionKeys = if _.isUndefined($scope.subscriptions) then [] else _.keys($scope.subscriptions.email).filter (subscription) ->
        !subscription.includes('push_')
      $scope.dirty = false
])
