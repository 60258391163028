import locationPinImage from 'images/v2/memory-bank/location-pin.png'

memoryBank.directive('memoryBank', [->
  restrict : "E"

  scope:
    user:               "=?" # can change, used by groups + admin/users
    module:             "=?" # can change, used by just about everything
    group:              "=?" # cannot change
    myGroup:            "=?" # cannot change
    aggregate:          "=?" # used by admin/sets
    demo:               "=?" # used by groups
    lastStudyTime:      "=?" # used by learn app (study all)
    eligibleItemsCount: "=?" # used by home page
    showDashboard:      "=?" # show group assignments after load on dashboard tab
    showHeader:         "=?" # overrides implicit showHeader settings
    forceAssignmentContext:  "=?" # displays the assignment name in the left header without a zoom out link
    memoryBankModal: "=?" # hides the right header for v3 pages and displays v3 text in the left header, also disables preload
    allowStanding:      "=?" # show standing tab if true (prevent it from showing on analytics page)

  templateUrl: Packs.iKnowRoutes.memory_bank_v2_templates_path("memory_bank")

  controller: ['$scope', '$analytics', '$q', '$window', '$timeout', '$filter', 'DebugHelper', 'UserManager', 'MemoryStorage', 'StudyItemsService', 'localStorageService', 'PassageHighlightingService', 'MemoryBankAssetHelper', 'MemoryBankLevelsService', 'hcHelper', 'FacetsResource', 'MySetsResource', 'MySeriesResource', 'MyMemoriesResource', 'MemoryBankResource', (
    $scope, $analytics, $q, $window, $timeout, $filter, DebugHelper, UserManager, MemoryStorage, StudyItemsService, localStorageService, PassageHighlightingService, MemoryBankAssetHelper, MemoryBankLevelsService, hcHelper, FacetsResource, MySetsResource, MySeriesResource, MyMemoriesResource, MemoryBankResource) ->
    DebugHelper.register("memoryBankCtrl", $scope)

    _.assignIn $scope,
      dirty: true
      routes: Packs.iKnowRoutes
      hideFooter: false
      unlockedLevels: []
      preload: true # set to true to prevent svg from starting an animation until we're ready
      themes: ["night", "day"]
      currentTheme: localStorageService.get("memoryBankTheme") || "night"
      levels: _.drop(MemoryBankLevelsService.levels, 1)
      facetsByGuid: {}
      forMobile: angular.element($window).width() < 768
      stateUI: {
        showStandingInstruction : false
      }

      viewModes: [
        { value: "Progress", label: "js.memory_bank.labels.progress"},
        { value: "Last Seen", label: "js.memory_bank.labels.last_seen"},
        { value: "Upcoming", label: "js.memory_bank.labels.upcoming"},
        { value: "Difficulty", label: "js.memory_bank.labels.difficulty"},
        { value: "Study Time", label: "js.memory_bank.labels.study_time"},
        { value: "Dashboard", label: "js.memory_bank.labels.dashboard"},
        { value: "About", label: "js.memory_bank.labels.about"}
      ]

      helpLevels: [
        name: "js.memory_bank.help_levels.level_new.name"
        class: "level_new"
        description_html: "js.memory_bank.help_levels.level_new.description_html"
      ,
        name: "js.memory_bank.help_levels.level_building.name"
        class: "level_building"
        description_html: "js.memory_bank.help_levels.level_building.description_html"
      ,
        name: "js.memory_bank.help_levels.level_1.name"
        class: "level_1"
        duration: "js.memory_bank.help_levels.level_1.duration"
        frequency: "js.memory_bank.help_levels.level_1.frequency"
        time: "js.memory_bank.help_levels.level_1.time"
        detail: "js.memory_bank.help_levels.level_1.detail"
        description_html: "js.memory_bank.help_levels.level_1.description_html"
      ,
        name: "js.memory_bank.help_levels.level_2.name"
        class: "level_2"
        duration: "js.memory_bank.help_levels.level_2.duration"
        frequency: "js.memory_bank.help_levels.level_2.frequency"
        time: "js.memory_bank.help_levels.level_2.time"
        study_time_item: "js.memory_bank.help_levels.level_2.study_time_item"
        study_time_set: "js.memory_bank.help_levels.level_2.study_time_set"
        study_duration: "js.memory_bank.help_levels.level_2.study_duration"
        description_html: "js.memory_bank.help_levels.level_2.description_html"
      ,
        name: "js.memory_bank.help_levels.level_3.name"
        class: "level_3"
        duration: "js.memory_bank.help_levels.level_3.duration"
        frequency: "js.memory_bank.help_levels.level_3.frequency"
        time: "js.memory_bank.help_levels.level_3.time"
        study_time_item: "js.memory_bank.help_levels.level_3.study_time_item"
        study_time_set: "js.memory_bank.help_levels.level_3.study_time_set"
        study_duration: "js.memory_bank.help_levels.level_3.study_duration"
        description_html: "js.memory_bank.help_levels.level_3.description_html"
      ,
        name: "js.memory_bank.help_levels.level_4.name"
        class: "level_4"
        duration: "js.memory_bank.help_levels.level_4.duration"
        frequency: "js.memory_bank.help_levels.level_4.frequency"
        time: "js.memory_bank.help_levels.level_4.time"
        study_time_item: "js.memory_bank.help_levels.level_4.study_time_item"
        study_time_set: "js.memory_bank.help_levels.level_4.study_time_set"
        study_duration: "js.memory_bank.help_levels.level_4.study_duration"
        description_html: "js.memory_bank.help_levels.level_4.description_html"
      ,
        name: "js.memory_bank.help_levels.level_master.name"
        class: "mastered"
        duration: "js.memory_bank.help_levels.level_master.duration"
        frequency: "js.memory_bank.help_levels.level_master.frequency"
        time: "js.memory_bank.help_levels.level_master.time"
        detail: "js.memory_bank.help_levels.level_master.detail"
        description_html: "js.memory_bank.help_levels.level_master.description_html"
      ]

      helpModes: [
        name: "js.memory_bank.help_mode.meaning.name"
        slug: "meaning"
        description: "js.memory_bank.help_mode.meaning.description"
      ,
        name: "js.memory_bank.help_mode.workload.name"
        slug: "workload"
        description: "js.memory_bank.help_mode.workload.description"
      ,
        name: "js.memory_bank.help_mode.frequency.name"
        slug: "frequency"
        description: "js.memory_bank.help_mode.frequency.description"
      ]

      helpModeClasses: (mode) ->
        classes = [mode.slug]
        classes.push("active") if mode.slug == $scope.currentHelpMode.slug
        classes

      setCurrentHelpMode: (mode) ->
        $scope.currentHelpMode = mode

      setCurrentTheme: (theme) ->
        $scope.currentTheme = theme

      setCurrentMode: (mode) ->
        $analytics.eventTrack(mode, { category: 'memory_bank_tab_switch' } )
        $scope.clearTooltip()
        previousMode =  $scope.currentMode
        $scope.currentMode = mode

        # This whole part can be simplified if reloadData was turned into a promise
        if mode == 'Dashboard'
          if previousMode == 'Standing'
            $scope.reloadData(true, () -> $scope.prepareDashboard())
          else
            $scope.prepareDashboard()
        else if previousMode == 'Standing'
          $scope.orbs = []
          $scope.reloadData(true)
        else if mode == 'Standing'
          if !$scope.currentModule
            $scope.stateUI.showStandingInstruction = true
          else
            $scope.reloadData(true)

      setCurrentUser: (user, forceSpinner = false) ->
        $scope.currentUser = user
        $scope.reloadData(forceSpinner)

      setCurrentModule: (module, forceSpinner = false) ->
        $scope.$emit "memoryBank:setCurrentModule", module
        $scope.currentModule = module
        if module
          $scope.currentModule.in_schedule = true
        else
          $scope.showScoringGoal = false

        if _.isNull(module) && $scope.currentMode == 'Standing'
          $scope.currentMode = 'Progress'

        $scope.reloadData(!module || forceSpinner).then () ->
          if module
            $scope.showScoringGoal = true

      facetForGuid: (guid) ->
        _.sample($scope.facetsByGuid[guid] || [])

      getOrbCta: (orb) ->
        if orb.studied_items_count > 0
          if orb.up_for_review
            "js.memory_bank.dashboard.cta.review_now"
          else
            "js.memory_bank.dashboard.cta.keep_learning"
        else
          "js.memory_bank.dashboard.cta.start_learning"

      getOrbCtaClasses: (orb) ->
        if orb.up_for_review && orb.studied_items_count > 0
          []
        else
          ["button--secondary"]

      getOrbNextReviewText: (orb) ->
        if orb.up_for_review && orb.studied_items_count > 0
          $filter('translate')("js.memory_bank.dashboard.asap")
        else
          "-"

      getLeftHeaderText: ->
        if $scope.memoryBankModal
          # v3 learn
          if $scope.group
            $scope.group.attributes.name
          else if $scope.currentModule
            $scope.currentModule.name || $scope.currentModule.attributes.name
          else
            $filter('translate')("js.memory_bank.header.welcome")
        else
          if $scope.currentUser # admin
            $filter('translate')("js.memory_bank.header.user")
          else if $scope.currentModule # v2 learn drilldown
            $scope.currentModule.name || $scope.currentModule.attributes.name
          else # v2 learn dashboard
            $filter('translate')("js.memory_bank.header.your")

      prepareDashboard: ->
        if $scope.orbs && $scope.orbs.length > 0 && $scope.orbs[0].orb_type == "facet"
          $scope.dashboardOrbs = $scope.orbs
        else
          $scope.dashboardOrbs = _.union($scope.orbsByLevel.unstarted, $scope.orbs)
        missingGuids = _.difference(_.map($scope.dashboardOrbs, "learning_engine_guid"), _.keys($scope.facetsByGuid))
        callback = ->
          for orb in $scope.dashboardOrbs
            orb.cta = $scope.getOrbCta(orb)
            orb.ctaClasses = $scope.getOrbCtaClasses(orb)
            orb.nextReviewText = $scope.getOrbNextReviewText(orb)
            unless orb.anchor
              facet = $scope.facetForGuid(orb.learning_engine_guid)
              orb.anchor = facet && facet.anchor
        if missingGuids.length > 0
          $scope.loadContentForGuids(missingGuids, callback)
        else
          callback()

      prepareFacet: (facet) ->
        facet.orb_type = "facet"
        facet.level_slug = MemoryBankLevelsService.binContinuousLevel(facet.level, facet.review_interval).slug
        facet.up_for_review = $scope.dateInFuture(facet.see_next_at)
        true

      launchStudySession: (module) ->
        moduleType = (if module.module_type == "set" then "sets" else module.module_type)
        StudyItemsService.loadStudyItem(moduleType, module.id, "recommended", $window.location.pathname).then (studyItem) ->
          $window.location.href = studyItem.learnPath

      prepareModule: (module) ->
        module.orb_type = "module"
        if module.module_type == "series"
          module.module_path = Packs.iKnowRoutes.v3_learn_series_path(module.id)
        else
          module.module_path = Packs.iKnowRoutes.v3_learn_set_path(module.id)

        module.level_slug = MemoryBankLevelsService.slugLevel(module.score)
        module.image ||= MemoryBankAssetHelper.defaultModuleOrb()
        module.up_for_review = $scope.dateInFuture(module.see_next_at)
        module.in_schedule = true
        true

      prepareUser: (user) ->
        user.orb_type = "user"
        user.level_slug = MemoryBankLevelsService.slugLevel(user.score)
        user.up_for_review = $scope.dateInFuture(user.see_next_at)

        if $scope.demo || $scope.allowStanding
          user.id = user.user.username
          user.name = "(anonymous)"
          if $scope.currentUser.attributes.username == user.id
            user.image =  $scope.currentUser.relationships?.image?.data?.attributes.url || locationPinImage
            user.has_image = $scope.currentUser.relationships?.image?.data?.attributes.url
            user.orb_type = "standing_user"
            user.unclickable = true
          else
            user.orb_type = "user"
            user.unclickable = true
            user.image = MemoryBankAssetHelper.defaultUserOrb(user.level_slug)
        else
          user.id = user.user.id
          user.name = user.user.name
          user.image = user.user.icon_image || MemoryBankAssetHelper.defaultUserOrb(user.level_slug)

        true

      finalizeLoad: (type, memories, optionalCallback) ->
        orbs = memories
        if orbs.length < 1
          $scope.$emit "memoryBank:noData"

        $scope.$emit "memoryBank:loadingCompleted" if (type == "facets")

        switch type
          when "facets"
            for orb in orbs
              $scope.prepareFacet(orb)
            if $scope.currentModule.hasOwnProperty("studied_items_count")
              # if module came back from LE it has items_count
              unstarted_items_count = ($scope.currentModule.items_count) - $scope.currentModule.studied_items_count
            else if $scope.currentModule.hasOwnProperty("attributes")
              # the module is a v3 object, so look inside attributes for the memories count
              unstarted_items_count = $scope.currentModule.attributes['memories-count'] - orbs.length
            else
              # if it came from Content, it has memories_count
              unstarted_items_count = $scope.currentModule.memories_count - orbs.length
            if !$scope.forMobile
              _.times unstarted_items_count, (i) ->
                $scope.orbsByLevel.unstarted.push({})
          when "modules"
            for orb in orbs
              $scope.prepareModule(orb)
          when "users"
            for orb in orbs
              $scope.prepareUser(orb)
          else
            throw new Error("Unexpected type of orb in knowledge bank: " + type)

        for orb in orbs
          if $scope.orbsByLevel[orb.level_slug]
            $scope.orbsByLevel[orb.level_slug].push(orb)

        locked = true

        if $scope.forMobile && $scope.currentModule
          maxLevelShown = Math.max($scope.currentModule.scoring_goal, $scope.currentModule.score)
          $scope.levels = _.reject(_.drop(MemoryBankLevelsService.levels,1), (level) ->
            level.intLevel > maxLevelShown
          )
          _.times $scope.levels.length, (i) ->
            level = $scope.levels[$scope.levels.length - i - 1]
            locked = locked && !($scope.orbsByLevel[level.slug].length > 0)
            level.header_locked = locked
            level.locked = false
        else
          _.times $scope.levels.length, (i) ->
            level = $scope.levels[$scope.levels.length - i - 1]
            locked = locked && !($scope.orbsByLevel[level.slug].length > 0)
            unlockedByDefault = level.slug == 'level_new' || level.slug == 'level_building'|| level.slug == "level_1" || level.slug == "unstarted"
            level.header_locked = false
            level.locked = (!unlockedByDefault) && locked

        if $scope.forMobile && !$scope.currentModule
          $scope.levels = _.reject($scope.levels, (level) -> level.locked)

        $scope.unlockedLevels = _.filter($scope.levels, locked: false)

        $timeout ->
          $scope.clearTooltip()
          $scope.orbs = _.reduce $scope.unlockedLevels, (memo, level) ->
            memo.concat($scope.orbsByLevel[level.slug])
          , []
          if optionalCallback
            optionalCallback()

        if $scope.currentModule
          if $scope.currentModule.meta?['memory-aggregate'] # MemoryBank on specific set/series page
            $scope.aggregateStats =
              active_count: $scope.currentModule.meta['memory-aggregate']['studied-items-count'] - $scope.currentModule.meta['memory-aggregate']['eligible-items-count']
              fading_count: $scope.currentModule.meta['memory-aggregate']['eligible-items-count']
              unstarted_count: $scope.currentModule.meta['memory-aggregate']['unstarted-items-count']
          else # MemoryBank on dashboard with specific set/series orb selected
            $scope.aggregateStats =
              active_count: $scope.currentModule.studied_items_count
              fading_count: $scope.currentModule.eligible_items_count
              unstarted_count: $scope.currentModule.items_count - $scope.currentModule.studied_items_count
        else
          studied_items = _.sum(_.map(orbs, "studied_items_count"))
          items_count = _.sum(_.map(orbs, "items_count"))
          eligible_items_count = _.sum(_.map(orbs, "eligible_items_count"))
          $scope.aggregateStats =
            active_count: studied_items - eligible_items_count
            fading_count: eligible_items_count
            unstarted_count: items_count - studied_items

        $scope.isHC = orbs.length > hcHelper.threshold
        $scope.hasEligibleItems = _.some(orbs, (orb) -> orb.eligible_items_count > 0)
        $scope.loadingOrb = null
        $scope.dirty = false

      reloadData: (forceSpinner, optionalCallback) ->
        $scope.dataRequestId = dataRequestId = Math.random()

        if $scope.error
          $scope.error = null
          $scope.dirty = true

        if forceSpinner
          $scope.dirty = true

        if $scope.currentModule && $scope.currentMode != 'Standing'
          $scope.currentModule.score = null
          $scope.currentModule.level_slug = null

        $scope.clearTooltip()

        $scope.orbsByLevel =
          unstarted: []
          level_new: []
          level_building: []
          level_1: []
          level_2: []
          level_3: []
          level_4: []
          mastered: []

        if $scope.forMobile
          $scope.viewModes = _.filter($scope.viewModes, (mode) ->
            mode.value == "Progress" || mode.value == "Dashboard"
          )

        UserManager.loadUser().then (user) ->
          if $scope.lastStudyTime
            $scope.showHeader = false
            if MemoryStorage.cache.my_memories
              # Second summary initial load for study all
              $scope.preload = true
              $scope.finalizeLoad("facets", MemoryStorage.cache.my_memories)
              $scope.fadingMemories = MemoryStorage.cache.my_memories
            else
              # Second summary second load for study all
              $scope.preload = false
              MyMemoriesResource.query
                studied_since: $scope.lastStudyTime
              , (success) ->
                recentMemories = success.response
                recentGuids = _.map(recentMemories,"learning_engine_guid")
                fadingMemories = _.reject($scope.fadingMemories, (m) -> _.includes(recentGuids,m.learning_engine_guid))
                memories = recentMemories.concat(fadingMemories)
                MemoryStorage.cache.my_memories = memories
                $scope.finalizeLoad("facets", memories)
          else if $scope.module?.module_type == "courses"
            $scope.preload = false
            $scope.showHeader = false
            params = { group_id: $scope.module.id }
            MyMemoriesResource.query params
            , (success) ->
              MemoryStorage.cache.my_memories = success.response
              $scope.finalizeLoad("facets", success.response)
          else if $scope.module?.module_type == "study_all"
            # Initial knowledge bank load for study all
            $scope.preload = false
            $scope.showHeader = false
            params = { fading_only: true }

            MyMemoriesResource.query params
            , (success) ->
              MemoryStorage.cache.my_memories = success.response
              $scope.finalizeLoad("facets", success.response)
          else
            constraints = {}

            if $scope.group
              constraints.group_id = $scope.group.id
            else
              constraints.user_id = user.id

            if $scope.myGroup
              constraints.my_group_id = $scope.myGroup.id

            if $scope.currentUser
              constraints.user_id = $scope.currentUser.username || $scope.currentUser.id

            # remove the score when switched from group individual user view to all user view
            if $scope.group && !$scope.currentUser && $scope.currentModule
              $scope.currentModule.score = null
              $scope.currentModule.level_slug = null

            if $scope.currentModule
              $scope.currentModule.scoring_goal_slug = MemoryBankLevelsService.slugLevel($scope.currentModule.scoring_goal)
              constraints.module_id = $scope.currentModule.id
              constraints.module_type = $scope.currentModule.module_type

              if $scope.aggregate && !$scope.currentUser
                constraints.user_id = null
                constraints.aggregate = true

            $scope.preload = !!(_.isUndefined($scope.orbs) && $scope.currentModule && !_.isUndefined(MemoryStorage.cache[MemoryStorage.cacheKey(constraints)]))
            if !!$scope.memoryBankModal
              $scope.preload = false

            if $scope.group && !($scope.currentModule || $scope.currentUser)
              return # Temporary fail in the groups logic, let's wait a bit

            if $scope.currentMode == 'Standing'
              $scope.stateUI.showStandingInstruction = false
              params = {
                group_id: $scope.group.id
              }
              if $scope.currentModule.module_type == "series"
                resource = "groupSeriesStanding"
                params.series_id = $scope.currentModule.id
              else
                resource = "groupGoalStanding"
                params.set_id = $scope.currentModule.id

              MemoryBankResource[resource] params
                , (success) ->
                  $scope.finalizeLoad("users", success.response)
            # don't show level for admin/sets, home, module with score already loaded, logged out, or group users
            else if $scope.aggregate || !$scope.currentModule || $scope.currentModule.score || !user || ($scope.group && !$scope.currentUser)
              $scope.loadData(constraints, dataRequestId)
            # TODO: Simplify this!!
            # Need to do this extra step to get user's set score. Make v3 api return it with aggregates.
            else
              $scope.loadModuleAgg(constraints).then (result) ->
                if (!_.isUndefined(result) && !_.isEmpty(result.memories))
                  result = result.memories[0]
                  $scope.currentModule.scoring_goal = result.scoring_goal
                  $scope.currentModule.scoring_goal_slug = MemoryBankLevelsService.slugLevel(result.scoring_goal)
                  $scope.currentModule.score = result.score
                  $scope.currentModule.level_slug = MemoryBankLevelsService.slugLevel($scope.currentModule.score)
                constraints.aggregate = false
                constraints.need_set_score = false
                $scope.loadData(constraints, dataRequestId, optionalCallback)

      loadModuleAgg: (constraints) ->
        constraints.need_set_score = true
        constraints.aggregate = true
        MemoryStorage.load(constraints)

      loadData: (constraints, dataRequestId, optionalCallback) ->
        MemoryStorage.load(constraints).then (response) ->
          # prevent race condition when two loads triggered simultaneously
          unless response.message == "OK"
            $scope.$emit "memoryBank:partialResults", response.message
          if $scope.dataRequestId == dataRequestId
            $scope.finalizeLoad(response.type, response.memories, optionalCallback)
        , (error) ->
          $scope.error = (error.data && error.data.meta && error.data.meta.message) || $filter('translate')("js.memory_bank.could_not_load")
          $scope.dirty = false

      toggleSchedule: (toggle) ->
        if $scope.tooltipOrb && $scope.tooltipOrb.orb_type == "module"
          fromTooltip = true
          module = $scope.tooltipOrb
        else
          fromTooltip = false
          module = $scope.currentModule
        $scope.resource =  if module.module_type == "set" then MySetsResource else MySeriesResource
        if toggle
          $scope.addToSchedule(fromTooltip, module)
        else
          $scope.removeFromSchedule(fromTooltip, module)

      addToSchedule: (fromTooltip, module) ->
        if $scope.reloadTimer
          $timeout.cancel($scope.reloadTimer)
        if fromTooltip
          $scope.$broadcast("orbToggle", true, $scope.tooltipOrb.id)
        $scope.resource.addToSchedule id: module.id,
        (success) ->
          console.log("Module added to schedule")

      removeFromSchedule: (fromTooltip, module) ->
        module.removing = true
        orbId = $scope.tooltipOrb?.id
        $scope.resource.removeFromSchedule id: module.id,
        (success) ->
          module.in_schedule = false
          MemoryStorage.reset()
          if fromTooltip
            $scope.$broadcast("orbToggle", false, orbId)
          $scope.reloadTimer = $timeout ->
            $scope.setCurrentModule(null, !fromTooltip)
          , 2000

      levelClasses: (level) ->
        classes = [level.slug]
        classes.push("locked") if level.locked
        classes

      themeClasses: (theme) ->
        classes = [theme]
        classes.push("active") if theme == $scope.currentTheme
        classes

      classModifier: ->
        switch $scope.currentMode
          when 'Progress'
            "progress"
          when "Difficulty"
            "difficulty"
          when "Last Seen", "Upcoming"
            "timeline"
          when "Study Time"
            "totaltime"
          when "Dashboard"
            "summary"

      dateInFuture: (date) ->
        (new Date(date)) <= (new Date())

      clearTooltip: ->
        $scope.tooltipOrb = null
        $scope.tooltipFacet = null
        $scope.tooltipDirty = false

      stopTooltipTimer: ->
        if $scope.tooltipTimer
          $timeout.cancel($scope.tooltipTimer)

      startTooltipTimer: ->
        $scope.stopTooltipTimer()
        $scope.tooltipTimer = $timeout $scope.clearTooltip, 3000

      revealTooltip: ->
        $scope.tooltipRevealed = true

      prepareTooltipFacets: (facets) ->
        for facet in facets
          if facet.anchor.image_map_node
            facet.anchor =
              region: facet.anchor.image_map_node
          else if facet.anchor.cloze_node
            facet.anchor =
              text_html: facet.anchor.cloze_node.cloze_text
          else if facet.anchor.sequence_node
            facet.anchor =
              text_html: "" + facet.anchor.sequence_node.position
          else
            facet.anchor = facet.anchor.concept
          facet.association = facet.association.concept

      loadContentForGuids: (guids, callback) ->
        if $scope.module && $scope.module.module_type == "study_all" && guids.length > 1
          params = { fading_only: true}
        else
          params = { "guids[]": guids }
        FacetsResource.query params
        , (success) ->
          facetsByGuid = _.groupBy(success.response,"learning_engine_guid")
          _.assignIn($scope.facetsByGuid, facetsByGuid)
          $scope.prepareTooltipFacets _.flatten(_.values(facetsByGuid))
          callback() if callback

    $scope.currentHelpMode = $scope.helpModes[0]

    $scope.levelsBySlug = _.keyBy($scope.levels, "slug")

    $scope.$on "memoryBank:selectModule", (e, orb) ->
      if orb
        $scope.loadingOrb =
          x: orb.x
          y: orb.y
      $scope.setCurrentModule(orb) if $scope.allowDirectModuleSelection

    $scope.$on "memoryBank:selectUser", (e, orb) ->
      if orb
        $scope.loadingOrb =
          x: orb.x
          y: orb.y
      $scope.setCurrentUser(orb, !orb)

    $scope.$on "memoryBank:mouseOver", (e, orb, orbOnLeft) ->
      return if $scope.currentMode == 'Standing'
      $scope.stopTooltipTimer()
      $scope.tooltipOrb = orb
      $scope.tooltipOrb.scoring_goal_slug = MemoryBankLevelsService.slugLevel(orb.scoring_goal)
      $scope.tooltipRevealed = false
      $scope.tooltipStyle = if orbOnLeft then { right: 0 } else { left: 0 }
      if orb.orb_type == "facet"
        $scope.tooltipFacet = $scope.facetForGuid(orb.learning_engine_guid)
        unless $scope.tooltipFacet
          $scope.tooltipDirty = true
          $scope.loadContentForGuids [orb.learning_engine_guid], ->
            $scope.tooltipFacet = $scope.facetForGuid(orb.learning_engine_guid)
            $scope.tooltipDirty = false

    $scope.$on "memoryBank:mouseOut", (e, orb) ->
      if orb
        $scope.startTooltipTimer()
      else
        $scope.clearTooltip()

    $scope.$on "memoryBank:preloadComplete", (e) ->
      $scope.preload = false
      MemoryStorage.reset()
      $scope.reloadData()

    $scope.$watch "currentTheme", ->
      for theme in $scope.themes
        $scope.element.toggleClass("theme-" + theme, theme == $scope.currentTheme)
      localStorageService.set "memoryBankTheme", $scope.currentTheme
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element

    $scope.allowDirectModuleSelection = !attrs.hasOwnProperty("myGroup")
    $scope.showHeader = if $scope.showHeader then true else (!attrs.hasOwnProperty("module") && !attrs.hasOwnProperty("myGroup"))
    $scope.forceAssignmentContext = !!$scope.forceAssignmentContext
    $scope.memoryBankModal = !!$scope.memoryBankModal

    if $scope.showDashboard
      # Assignments MB: Need to wait for data to load before preparing dashboard
      runOnce = true
      $scope.$watch "orbs", (newVal, oldVal) ->
        if newVal && runOnce
          runOnce = false
          $scope.setCurrentMode("Dashboard")
    else
      $scope.setCurrentMode($scope.viewModes[0].value)

    # forces initial data load
    $scope.currentUser = $scope.user
    $scope.setCurrentModule($scope.module)

    if $scope.allowStanding && $scope.group && $scope.currentUser && ($scope.currentUser.meta["is-admin"] || $scope.group.relationships.partner.data.attributes["partner-settings"]["standing-enabled"])
      $scope.viewModes.push({ value: "Standing", label: "js.memory_bank.labels.standing" })

    # Watch for changes in user (Group MB only)
    $scope.$watch "user", (newVal, oldVal) ->
      if newVal != oldVal
        $scope.setCurrentUser(newVal, true)

    # Watch for changes in module (Group MB only)
    $scope.$watch "module", (newVal, oldVal) ->
      if newVal != oldVal
        $scope.setCurrentModule(newVal, true)
])

memoryBank.filter('duration', ['timeHelper', (timeHelper) ->
  timeHelper.msecToString
])
