import UserCookie from 'javascripts/v2/modules/core/user-cookie-helper'

siteNavigationApp.controller("userProfileEditModal", ['$scope', '$routeParams', '$window', '$filter', '$cookies', 'DebugHelper', 'UserManager', 'UsersResource', 'EmailsResource', 'LanguagesResource', 'TimezonesResource', 'MyProfileResource', 'MyGroupsResource', 'V3OAuthResource', ($scope, $routeParams, $window, $filter, $cookies, DebugHelper, UserManager, UsersResource, EmailsResource, LanguagesResource, TimezonesResource, MyProfileResource, MyGroupsResource, V3OAuthResource) ->
  DebugHelper.register("userProfileEditModal", $scope)

  bootstrapData = angular.element("#top-nav--product-switcher").data()
  _.assignIn $scope,
    currentTab: "account"
    member_ids: {}
    toggles: {}
    defaultUserImage: bootstrapData.defaultUserImage
    available_locales: AVAILABLE_LOCALES # global vars passed in via webpack.DefinePlugin
    user_min_password_len: USER_MIN_PASSWORD_LEN

    addEmail: ->
      $scope.clearStatus(true)
      EmailsResource.create { email_address: $scope.user.new_email }
      , (success) ->
        $scope.emails.push(success.response)
        $scope.user.new_email = null
        $scope.setStatus(true, "js.user_profile.edit_user.email_added_message")
      , (error) ->
        $scope.setStatus(false, error.data.response)

    deleteEmail: (email) ->
      $scope.clearStatus(true)

      $scope.emails = _.filter $scope.emails, (em) ->
        email.id != em.id
      EmailsResource.delete { id: email.id }
      , (success) ->
        $scope.setStatus(true, "js.user_profile.edit_user.email_removed_message")
      , (error) ->
        $scope.setStatus(false, error.data.response)

    confirmEmail: (email) ->
      $scope.clearStatus(true)

      EmailsResource.confirm_email { email_id: email.id }
      , (success) ->
        $scope.setStatus(true, "js.user_profile.edit_user.email_confirmation_message")
      , (error) ->
        $scope.setStatus(false, error.data.response)

    setPrimaryEmail: (email) ->
      for e in $scope.emails
        e.attributes.primary = false
      email.attributes.primary = true #show immediate feedback
      $scope.clearStatus(true)

      EmailsResource.make_primary { id: email.id }
      , (success) ->
        $scope.setStatus(true, "js.user_profile.edit_user.email_changes_message")
      , (error) ->
        $scope.setStatus(false, error.data.response)

    clearStatus: (flag) ->
      $scope.status =
        dirty: flag
        success: false
        message: null

    setStatus: (success, message) ->
      $scope.status =
        dirty: false
        success: success
        message: message

    updateUser: ->
      $scope.clearStatus(true)
      params =
        name: $scope.user.attributes.name
        manual_timezone: $scope.user.timezone && $scope.user.timezone.name
        native_language_id: $scope.user.language && $scope.user.language.id
        locale: $scope.user.locale.code

      UsersResource.save({ id: $scope.user.id }, params, (success) ->
        cookie = new UserCookie($cookies)
        cookie.setLocale(success.response.locale).write()
        $scope.setStatus(true, "js.user_profile.edit_user.profile_saved_message")
        $window.location.reload()
      , (error) ->
        $scope.setStatus(false, error.data.response)
      )

    updatePassword: ->
      $scope.clearStatus(true)
      params = _.pick($scope.user, ['id', 'password', 'current_password', 'password_confirmation'])
      UsersResource.save { id: $scope.user.id }, params
      , (result) ->
        $scope.user.current_password = null
        $scope.user.password = null
        $scope.user.password_confirmation = null
        $scope.setStatus(true, "js.user_profile.edit_user.password_saved_message")
      , (error) ->
        $scope.setStatus(false, error.data.response)

    fetchTimeZone: ->
      TimezonesResource.query {}, (result) ->
        $scope.timezones = result.response
        if $scope.user.attributes.timezone
          $scope.user.timezone = _.find($scope.timezones, name: $scope.user.attributes.timezone)

    languageDBCodeFromLocaleCode: (locale) ->
      if locale == 'zh-TW'
        'zh-Hant'
      else if locale == 'zh-CN'
        'zh-Hans'
      else
        locale

    fetchLanguages: ->
      LanguagesResource.query {}, (result) ->
        $scope.native_languages = result.response
        $scope.locales = _.map $scope.available_locales, (locale) ->
          name: _.find($scope.native_languages, code: $scope.languageDBCodeFromLocaleCode(locale)).native_name
          code: locale

        $scope.user.locale = _.find($scope.locales, code: $scope.user.attributes.locale || DEFAULT_LOCALE)

        if $scope.user.relationships.language.data
          $scope.user.language = _.find($scope.native_languages, id: parseInt($scope.user.relationships.language.data.id))

    hasAuth: (arg) ->
      return false unless $scope.user
      switch arg
        when "google"
          $scope.user.relationships["google-auth"].data
        when "password"
          $scope.user.attributes["has-password"]
        else
          false

    buildMemberIDHash: () ->
      $scope.user.partner_member_ids = _.map $scope.member_id_partners, (partner) ->
        member_partner = _.find $scope.user.relationships["user-partner-member-ids"].data, (member_id) -> member_id.relationships.partner.data.id == partner.id.toString()
        {
          user_id:      $scope.user.id
          partner_id:   partner.id
          member_id:    (member_partner && member_partner.attributes["member-id"]) || ""
          partner_name: partner.name
          partner_slug: partner.partner_settings.member_id_slug
        }

    updateMemberIDs: ->
      #TODO - this should only take one call
      for pm_id in $scope.user.partner_member_ids
        unless pm_id.member_id.length == 0
          $scope.updateUserMemberId(pm_id)

    updateUserMemberId: (pm_id)->
      #TODO - errors should be per member id since we allow mass update
      UsersResource.save { id: $scope.user.id }, { user_partner_member_ids: pm_id }
      , (success) ->
        $scope.showSuccess = true
      , (error) ->
        $scope.showError = true

    disconnectable: ->
      $scope.hasAuth('password')

    disconnectGoogle: ->
      if $window.confirm($filter('translate')("js.user_profile.edit_user.unlink_google_confirm"))
        V3OAuthResource.delete
          provider: "google"
          , (success) ->
            $scope.user.relationships["google-auth"].data = null
          , DebugHelper.logError


    getGroupPartners: ->
      filteredGroups = _.filter $scope.groups, (group) ->
        !!group.partner

      $scope.partners = _.map filteredGroups, (group) ->
        group.partner

      $scope.partners = _.uniqBy($scope.partners, 'id')
      $scope.member_id_partners = _.filter $scope.partners, (partner) ->
        partner.partner_settings?.member_id_enabled

    loadGroupInfo: ->
      MyGroupsResource.query { with_partner_info: true }
      , (success) ->
        $scope.groups = success.response
        $scope.getGroupPartners()
        $scope.buildMemberIDHash()
        $scope.emails = $scope.user.relationships.emails.data
        $scope.fetchTimeZone()
        $scope.fetchLanguages()

  UserManager.loadUser().then (currentUser) ->
    $scope.user = currentUser
    if $scope.user.relationships.image.data
      $scope.user.image =
        id: $scope.user.relationships.image.data.id
        url: $scope.user.relationships.image.data.attributes.url
    $scope.loadGroupInfo()

  $scope.$watch "user.image", (newVal, oldVal) ->
    if newVal != oldVal
      icon_image_id = $scope.user.image && $scope.user.image.id
      UsersResource.save
        id: $scope.user.id
        icon_image_id: icon_image_id
      , (success) ->
        console.log("image is updated")
])
