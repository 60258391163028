require('./memory-bank-app.js')
require('./site-navigation-app.js')
require('./signup-app.js')
require('./workload-calculator-app.js')

window.ceregoLayoutApp = angular.module("cerego.layout", [
  "ngRoute"
  "cerego.common"
  "cerego.memoryBank"
  "cerego.navigations"
  "cerego.signup"
  "cerego.workloadCalculator"
])
.config(["localStorageServiceProvider", "$routeProvider", (localStorageServiceProvider, $routeProvider) ->
  localStorageServiceProvider.setStorageCookie(1, '/')
  $routeProvider
  .when Packs.iKnowRoutes.v2_partner_notifications_path(":partner_id"),
    templateUrl : Packs.iKnowRoutes.partners_v2_templates_path("notifications")
    controller : 'partnerNotificationsCtrl'
  .when Packs.iKnowRoutes.v2_unsubscribe_path(":token"),
    templateUrl : Packs.iKnowRoutes.v2_template_path("subscriptions")
    controller  : 'subscriptionsCtrl'
    resolve:
      $titleTag: -> ("js.title_tags.manage_email_settings")
      $headerTheme: -> 'minimal'
  .when Packs.iKnowRoutes.v2_partner_unsubscribe_path(":partner_id", ":token"),
    templateUrl : Packs.iKnowRoutes.v2_template_path("partner_subscription")
    controller  : 'subscriptionsCtrl'
    resolve:
      $headerTheme: -> 'minimal'
  .when Packs.iKnowRoutes.workload_v2_static_path(),
    templateUrl : Packs.iKnowRoutes.static_v2_templates_path("workload")
    controller  : 'workloadCalculatorCtrl'
    resolve:
      $headerTheme: -> 'static'
  .when Packs.iKnowRoutes.lti_inactivity_logout_v3_static_path(),
    templateUrl : Packs.iKnowRoutes.static_v3_templates_path("lti_inactivity_logout")
    resolve:
      $headerTheme: -> 'hidden'
  .when Packs.iKnowRoutes.upgrade_v2_browser_path(),
    templateUrl : Packs.iKnowRoutes.browsers_v2_templates_path("upgrade")
    resolve:
      $titleTag: -> ("js.title_tags.upgrade_browser")
      $headerTheme: -> 'minimal'
  .when Packs.iKnowRoutes.v3_lti_users_confirm_path(),
    templateUrl : Packs.iKnowRoutes.lti_users_v3_templates_path("confirm")
    controller  : 'ltiConfirmCtrl'
    resolve:
      $headerTheme: -> 'hidden'
      $titleTag: -> "js.title_tags.confirm_email"
  .otherwise
    template : "<div></div>"
    controller: ['$window', ($window) ->
      $window.location.href = Packs.iKnowRoutes.v3_signin_path()
    ]
])

require('./layout/resources/v3-lti-confirmation-mailer-resource.js')

require('./layout/edit-partners-service.js')
require('./layout/module_drop_down.js')
require('./layout/set-export-service.js')
require('./layout/tileable.js')

# replace these with explicit import
req = require.context('./layout/controllers', false)
req.keys().forEach(req)
