# Hack to get inclusion working with ng-repeat in Angular 1.3+
# see: https://github.com/angular/angular.js/issues/7874#issuecomment-47647528
ceregoLayoutApp.directive('inject', [->
  link: ($scope, $element, $attrs, controller, $transclude) ->
    innerScope = $scope.$new()
    $transclude innerScope, (clone) ->
      $element.empty()
      $element.append(clone)
      $element.on '$destroy', ->
        innerScope.$destroy()
])

ceregoLayoutApp.directive('cgTileable', ['$window', ($window) ->
  restrict: "E"
  transclude: true

  scope:
    collection: "="
    offset: "&"
    padding: "="
    tileSize: "=?"

  template: '<div ng-repeat="row in rows()" inject ng-style="{ width: batchWidth + \'px\' }"></div>'

  controller: ['$scope', '$filter', 'DebugHelper', 'ModulePermissionsHelper', ($scope, $filter, DebugHelper, ModulePermissionsHelper) ->
    DebugHelper.register("cgTileable", $scope)

    _.assignIn $scope, {
      parent: $scope.$parent
      batchSize: 3
      maxBatches: 4
      batchWidth: 0
      elementSize: $scope.tileSize || 300
      offsetPadding: $scope.padding || 20
      routes: Packs.iKnowRoutes

      ModulePermissionsHelper: ModulePermissionsHelper

      rows: ->
        $filter("inBatches")($scope.collection, $scope.batchSize)

      rowTiles: ($index) ->
        _.take(_.drop($scope.collection, $index*$scope.batchSize), $scope.batchSize)

      computeBatchSize: ->
        containerSize = angular.element($window).innerWidth() - $scope.offset() - $scope.offsetPadding
        $scope.batchSize = Math.min(Math.max(Math.floor(containerSize / ($scope.elementSize + $scope.offsetPadding)), 1), $scope.maxBatches)
        $scope.batchWidth = ($scope.elementSize + $scope.offsetPadding) * $scope.batchSize - $scope.offsetPadding
        if $scope.currentTile && !_.isUndefined($scope.vIndex)
          index = _.indexOf($scope.collection, $scope.currentTile)
          $scope.vIndex = Math.floor(index / $scope.batchSize)
          $scope.hIndex = index % $scope.batchSize

        angular.element(document).trigger("tileable:batchResize", $scope.batchWidth)

      isCurrentTile: (tile) ->
        tile == $scope.currentTile && !_.isUndefined($scope.vIndex)

      arrowStyle: ->
        if _.isUndefined($scope.hIndex)
          return
        offset = $scope.hIndex * ($scope.elementSize + $scope.offsetPadding) + $scope.elementSize/2 - 15
        { left: offset + "px"}

      selectTile: (tile, vIndex, hIndex) ->
        if !tile || $scope.isCurrentTile(tile)
          $scope.vIndex = undefined
        else
          $scope.vIndex = vIndex
          $scope.hIndex = hIndex
          $scope.currentTile = tile

      getEditText: (tile) ->
        if tile.goal_type == 'assessment'
          $filter('translate')("js.old.sets.index.edit_assessment")
        else if tile.goal_type == 'survey'
          $filter('translate')("js.old.sets.index.edit_survey")
        else
          $filter('translate')("js.old.sets.index.edit_set")

      getTileIconClass: (tile) ->
        if tile.goal_type == 'assessment'
          'assessment'
        else if tile.goal_type == 'survey'
          'fa fa-poll-h'
        else
          tile.module_type.toString()


      shouldShowDetails: ($index) ->
        if _.isUndefined($scope.vIndex)
          return
        $index == $scope.vIndex

    }
  ]

  link: ($scope, element, attrs) ->
    $scope.computeBatchSize()

    angular.element($window).bind 'resize', ->
      $scope.$apply ->
        $scope.computeBatchSize()

    $scope.$on '$destroy', ->
      angular.element($window).unbind('resize')

    $scope.$watch("collection", ->
      if $scope.collection.length == 0
        $scope.selectTile(undefined)
    )
])
