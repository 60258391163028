memoryBank.directive('memoryBankVisual', ['$timeout', '$filter', ($timeout, $filter) ->
  restrict : "A"

  scope:
    orbs: "=memoryBankVisual"
    levels: "="
    mode: "="
    preload: "="
    selectedOrb: "="
    isDemo: "="
    module: "="


  controller: ['$scope', '$window', 'DebugHelper', 'progressVisual', 'difficultyVisual', 'timelineVisual', 'totalTimeVisual', 'hcHelper', 'MemoryBankLevelsService', ($scope, $window, DebugHelper, progressVisual, difficultyVisual, timelineVisual, totalTimeVisual, hcHelper, MemoryBankLevelsService) ->
    DebugHelper.register("memoryBankVisual", $scope)

    lockedLevelSize = if angular.element($window).width() > 768 then 65 else 40

    _.assignIn $scope,
      MemoryBankLevelsService: MemoryBankLevelsService
      # Move nodes to top of stack on hover
      moveToFront: (selection) ->
        selection.each ->
          if this.parentNode
            this.parentNode.appendChild(this)

      # Update node DOM position
      moveToPosition: (selection) ->
        if $scope.visual.isHC && $scope.visual.hasOwnProperty("hcAnimate")
          selection.call($scope.visual.hcAnimate)
        else
          selection.attr
            transform: (d) ->
              "translate(" + [d.x, d.y] + ")"

      tick: (selection) ->
        # Reset tick counters
        tick = 0
        $scope.force.on "start", ->
          tick = 0

        selection.each (d) ->
          d.startTick = Math.round(Math.random()*12)

        # Actual tick function
        (e) ->
          alpha = e.alpha
          k = .4
          wiggle = .2
          tick += 1

          selection.each (node) ->
            # move closer to target with a little wiggle to prevent local minima (aka local gravity)
            if tick > node.startTick && node.weight == 0
              node.x += (node.target.x - node.x)*k*alpha
              node.y += (node.target.y - node.y)*k*alpha
              if $scope.performInitialCollisions
                node.y += (Math.random()*wiggle - wiggle/2)*k*alpha

            # bounding box
            if $scope.enforceFullBoundingBox || $scope.mode != "Progress" || alpha < .01
              node.x = Math.max($scope.visual.bb.left, Math.min(node.x, $scope.visual.bb.right))
            node.y = Math.max($scope.visual.bb.top, Math.min(node.y, $scope.visual.bb.bottom))

          # Perform collisions
          if $scope.performInitialCollisions
            quadtree = d3.geom.quadtree($scope.force.nodes())
            selection.each (node) ->
              padding = -node.radius*.2
              r = node.radius + padding / 2
              nx1 = node.x - r
              nx2 = node.x + r
              ny1 = node.y - r
              ny2 = node.y + r

              quadtree.visit (quad, x1, y1, x2, y2) ->
                if quad.point && (quad.point != node) && $scope.visual.shouldCollide(node, quad.point)
                  dx = node.x - quad.point.x
                  dy = node.y - quad.point.y
                  # actual distance
                  l = Math.sqrt(dx * dx + dy * dy)
                  # desired distance
                  r = node.radius + quad.point.radius + padding
                  if l < r
                    l = $scope.visual.collisionForce(l, r, alpha)
                    node.x -= dx *= l
                    node.y -= dy *= l
                    quad.point.x += dx
                    quad.point.y += dy
                x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1
          else
            # Finally, update the dom
            if e.alpha < 0.05
              selection.call($scope.moveToPosition)
              $scope.force.stop()

      onMouseOver: (selection) ->
        selection.filter (d) ->
          return if $scope.isDemo
          $scope.force.links().length == 0 || d.weight > 0
        .each (d) ->
          $scope.$emit "memoryBank:mouseOver", d, d.x * 2 < $scope.element.width()
        .call($scope.moveToFront)

      onMouseOut: (selection) ->
        selection.each (d) ->
          $scope.$emit "memoryBank:mouseOut", d

      pulse: (circle) ->
        repeat=()->
          circle.transition()
                .duration(1500)
                .attr("r", 0)
                .attr("stroke-width", 4)
                .transition()
                .delay(2000)
                .duration(1500)
                .attr("r", 15)
                .attr("stroke-width", 0.5)
                .ease('bounce')
                .each("end", repeat)
        repeat()

      updateData: (data, selection) ->
        keyFn = (d) ->
          if d.orb_type == "facet"
            d.orb_type + ":" + d.learning_engine_guid
          else
            d.orb_type + ":" + d.id

        if $scope.visual.isHC
          selection.data($scope.visual.data, keyFn)
        else
          # Store old data since data() will clobber it
          oldPositions = {}
          selection.each (d) ->
            oldPositions[keyFn(d)] = _.pick(d,["x", "y", "radius"])

          # Update data
          selection = selection.data(data, keyFn)

          # Restore data
          selection.each (d) ->
            oldPos = oldPositions[keyFn(d)]
            if oldPos
              d.px = d.x = oldPos.x
              d.py = d.y = oldPos.y
              d.radius = oldPos.radius

          selection

      createNode: (selection) ->
        if $scope.visual.isHC
          selection.call($scope.visual.createNode)
        else
          selection.call($scope.createOrb)

      # Called for new nodes
      createOrb: (selection) ->
        selection.classed
          "progress-orb":    true
          "progress-module": (d) -> d.orb_type == "module"
          "progress-standing-user": (d) -> d.orb_type == "standing_user"
          "progress-facet":  (d) -> d.orb_type == "facet" && !d.pulse
          "progress-user":   (d) -> d.orb_type == "user"
          "progress-pulse":   (d) -> d.pulse
          "progress-disabled": (d) -> d.disabled
          "progress-remixed": (d) -> d.remixed
          "progress-standing-pin": (d) -> d.orb_type == "standing_user" && !d.has_image
        .call($scope.visual.setSourcePosition($scope.parentOrb, $scope.preload))
        .call($scope.moveToPosition)
        .attr
          module_id: (d) -> d.id if d.orb_type == "module"

        selection.each (d) ->
          d.radius = $scope.radius(d.orb_type)
          that = this
          $(this).hoverIntent ->
            $scope.$apply ->
              d3.select(that).call($scope.onMouseOver)
          , ->
            $scope.$apply ->
              d3.select(that).call($scope.onMouseOut)

        selection.append "circle"
        .attr
          cx: 0
          cy: 0
          r: (d) -> d.radius

        selection.each (d) ->
          if d.pulse
            circle = selection.filter(".progress-pulse").select("circle")
            circle.call($scope.pulse, circle)

        selection.filter (d) ->
          d.orb_type != "facet"
        .each (d) ->
          d3.select(this).append "image"
          .attr
            "xlink:href": (d) ->
              $filter("transcode")(d.image, 40)
            "clip-path": (d) ->
              if d.orb_type == "user"
                "url(#userClipPath)"
              else
                "url(#moduleClipPath)"
            preserveAspectRatio: "xMidYMid slice"
            x: -d.radius + 4
            y: -d.radius + 4
            width: (d.radius - 4)*2
            height: (d.radius - 4)*2
            title: (d) -> d.name

          if d.remixed
            d3.select(this).append "circle"
            .attr
              cx: 0
              cy: 0
              r: (d) -> d.radius
              "fill-opacity": 0.5
              "fill": "#fff"

            d3.select(this).append "text"
            .text (d) ->  '\uf0c5'
            .attr
              "x": -11
              "y": 6

        selection.on "click", (d) ->
          $scope.$apply ->
            if $scope.isDemo
              $scope.$emit "memoryBank:demoStarted"
            else if d.unclickable
              # Do nothing
            else if $scope.mode != "Progress" || d.links.length < 1 || d.weight > 0 && !$scope.isHC
              # Open up the module
              if d.orb_type == 'module'
                $scope.parentOrb = d
                $scope.$emit "memoryBank:selectModule", d
              else if d.orb_type == 'user'
                $scope.parentOrb = d
                $scope.$emit "memoryBank:selectUser", d
            else if !$scope.isHC
              # Spread out nearby modules
              $scope.enforceFullBoundingBox = true
              selection.call($scope.resetAnimation)
              d.fixed = true

              if d.links.length > 10
                # TODO: play with this a little more, feels off for large clumps
                $scope.force.linkDistance(d.radius*8).linkStrength(.2)
              else
                $scope.force.linkDistance(d.radius).linkStrength(1)

              $scope.force.links(d.links)
              selection.call($scope.doAnimate)
              .filter (d) ->
                d.weight > 0
              .call($scope.moveToFront)

      generateAdjacencyMatrix: (selection) ->
        selection.each (d) ->
          d.links = []
          selection.filter (node) ->
            dx = node.target.x - d.target.x
            dy = node.target.y - d.target.y
            node != d && Math.sqrt(dx*dx + dy*dy) < d.radius*2
          .each (node) ->
            d.links.push
              source: d
              target: node

      resetAnimation: (selection) ->
        $scope.force.links([])
        selection.each (d) ->
          d.px = d.x
          d.py = d.y
          d.fixed = false

      doAnimate: (selection) ->
        return if $scope.isHC
        $scope.force.start() # need to use start and not resume in order to rebuild internal link strengths
        selection.classed
          background: false
          selected: (d) -> d.weight > 0
        if $scope.force.links().length > 0
          selection.classed
            background: (d) -> d.weight == 0

      # If you change these make sure you also change the clipPath radius in the template
      radius: (orb_type) ->
        switch orb_type
          when "module", "standing_user"
            24
          when "user"
            16
          else
            8

      updateSVG: (data) ->
        if $scope.isDemo
          $scope.totalLevels = $scope.levels.length
        else if angular.element($window).width() > 768
          $scope.totalLevels = MemoryBankLevelsService.totalLevelsExcludeUnstarted
        else if $scope.module
          $scope.totalLevels = _.max([$scope.levels.length, $scope.module.scoring_goal + 2])
        else
          $scope.totalLevels = $scope.levels.length

        svgDimensions =
          x: angular.element(".memory-bank--top-container").width()
          y: $scope.element.parent().height()

        $scope.isHC = (data.length > 0) && (data.length > hcHelper.threshold(data[0].orb_type == "facet"))

        switch $scope.mode
          when "Difficulty"
            $scope.visual = difficultyVisual
          when "Upcoming"
            $scope.visual = timelineVisual("see_next_at")
          when "Last Seen"
            $scope.visual = timelineVisual("last_study_time")
          when "Study Time"
            $scope.visual = totalTimeVisual("total_study_time_millis")
          else
            $scope.visual = progressVisual
            svgDimensions =
              x: angular.element(".memory-bank-progress-container").width() - (($scope.totalLevels - $scope.levels.length) * lockedLevelSize)
              y: $scope.element.parent().height()
            if $scope.module
              options = _.pick($scope.module, ['score', 'level_slug', 'scoring_goal', 'scoring_goal_slug', 'old_score', 'progress'])

              if $scope.module.hasOwnProperty("attributes")
                # the module is a v3 object
                options.scoring_goal = $scope.module.meta['level-goal']
                options.scoring_goal_slug = MemoryBankLevelsService.slugLevel(options.scoring_goal)
                # TODO pull out the other fields?

        $scope.visual.isHC = $scope.isHC
        $scope.svg.select(".memory-bank--level-line").remove()
        $scope.svg.select(".memory-bank--scoring-goal-line").remove()

        radius = $scope.radius(data.length > 0 && data[0].orb_type)

        $scope.visual.updateScales(svgDimensions, data, radius, $scope.levels, options)

        $scope.svg.call($scope.visual.updateAxis)

        # TODO: replace with one common style
        selection = $scope.svg.selectAll(".progress-orb, .progress-heatmap, .timeline-bargraph, .difficulty-orb")

        selection = $scope.updateData(data, selection)

        # Create new nodes
        selection.enter().append("g").call($scope.createNode)

        # Kill old ones
        selection.exit().remove()

        selection.classed
          "level_new":       (d) -> d.level_slug == "level_new"
          "level_building":  (d) -> d.level_slug == "level_building"
          "level_1":   (d) -> d.level_slug == "level_1"
          "level_2":   (d) -> d.level_slug == "level_2"
          "level_3":   (d) -> d.level_slug == "level_3"
          "level_4":   (d) -> d.level_slug == "level_4"
          "mastered":  (d) -> d.level_slug == "mastered"
          timelineFaded: false

        selection.filter (d) ->
          d.orb_type != "facet"
        .each (d) ->
          d3.select(this).select "image"
            .attr
              "xlink:href": (d) ->
                $filter("transcode")(d.image, 40)

        # Rebuild force layout
        if $scope.force
          $scope.force.stop()

        $scope.force = d3.layout.force()
                                .nodes(_.filter(selection.data(), (d) -> !_.isUndefined(d)))
                                .gravity(0)
                                .charge(0)
                                .friction(.6)
                                .charge (d) ->
                                  if d.weight > 0
                                    -20 * d.radius
                                  else
                                    0
                                .chargeDistance(100)
                                .size [svgDimensions.x, svgDimensions.y]

        $scope.force.on "tick", $scope.tick(selection)

        # Cancel out of selection
        $scope.element.on "click", (e) ->
          $scope.$apply ->
            $scope.enforceFullBoundingBox = true
            if e.target == $scope.element[0]
              selection.call($scope.resetAnimation).call($scope.doAnimate)
              $scope.$emit "memoryBank:mouseOut"

        # Calculate theoretical final position
        selection.call($scope.resetAnimation).call($scope.visual.setTargetPosition)

        $scope.enforceFullBoundingBox = false
        if $scope.visual.shouldPerformCollisions
          # Calculate true final position (with collisions)
          $scope.performInitialCollisions = true
          selection.each (d) ->
            d.source =
              x: d.x
              y: d.y

          $scope.force.start()
          $scope.force.tick() for i in [1..200] # TODO: cancel this loop on state change (mode/module/user)

          selection.each (d) ->
            d.target.x = d.x
            d.target.y = d.y
            d.px = d.x = d.source.x
            d.py = d.y = d.source.y

        if !$scope.isHC && ($scope.visual == progressVisual)
          $scope.svg.call($scope.visual.addGoalLines)

        # Perform animating simulation with timers between ticks
        $scope.performInitialCollisions = false
        if $scope.preload
          $scope.$emit "memoryBank:preloadComplete"
        else if $scope.isHC
          selection.each (d) ->
            d.px = d.x = d.target.x
            d.py = d.y = d.target.y
          selection.call($scope.moveToPosition)
        else
          $scope.svg.call($scope.visual.animateLevelLine) if ($scope.visual == progressVisual)
          selection.call($scope.doAnimate)

        # Precompute adjacency matrix
        selection.call($scope.generateAdjacencyMatrix)
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element
    $scope.svg = d3.select(element[0])

    defs = $scope.svg.append("defs")

    defs.append "clipPath"
    .attr
      id: "moduleClipPath"
    .append "circle"
    .attr
      cx: 0
      cy: 0
      r: 20

    defs.append "clipPath"
    .attr
      id: "userClipPath"
    .append "circle"
    .attr
      cx: 0
      cy: 0
      r: 12

    $scope.svg.append("g").classed("axis", true)
    $scope.svg.append("g").classed("grid", true)
    nowLine = $scope.svg.append("g").classed("nowLine", true)
    nowLine.append("line")
    nowLine.append("text").text($filter('translate')("js.memory_bank.current_time"))

    $scope.$watchCollection "orbs", (data, oldData) ->
      if data && (data != oldData)
        $timeout ->
          $scope.updateSVG(data)
          $scope.parentOrb = null

    $scope.$on "orbToggle", ($event, toggle, orbId) ->
      d3.select("[module_id='" + orbId + "']").classed("progress-orb--removed", !toggle)

    $scope.$watch "mode", (mode, oldMode) ->
      if $scope.orbs
        $timeout ->
          $scope.updateSVG($scope.orbs)
])
