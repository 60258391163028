siteNavigationApp.factory("SiteNavigationService", ['$rootScope', '$routeParams', 'DebugHelper', ($rootScope, $routeParams, DebugHelper) ->
  $scope = $rootScope.$new()

  DebugHelper.register("SiteNavigationService", $scope)

  _.assignIn $scope,
    context: null
    context_type: null
    subcontext_type: null
    product: null

    # Learn, create, or courses
    setProduct: (product) ->
      $scope.product = product

    setCourseId: (courseId) ->
      $scope.lastCourseContext = $scope.context
      $scope.courseId = courseId

    setContext: (context, context_type, subcontext_type = null) ->
      $scope.context = context
      # Clear the course if we change context
      if $scope.lastCourseContext != context
        $scope.setCourseId(null)
      $scope.context_type = context_type
      $scope.subcontext_type = subcontext_type
      $rootScope.$broadcast "siteNav:updateUI"

    isLearn: () ->
      $scope.product == 'learn'

    isCreate: () ->
      $scope.product == 'create'

    reset: () ->
      $scope.context = null
      $scope.context_type = null
      $scope.subcontext_type = null

    isV4Embed: () ->
      sameOrigin = false
      try
        sameOrigin = process.env.RAILS_ENV == 'development' || window.parent.location.host == window.location.host;
       catch e
        sameOrigin = false
      !!$routeParams.isV4Embed ||  (sameOrigin && window.parent.location.pathname.split('/')[1] == 'app')
])
