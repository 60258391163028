memoryBank.service("MemoryBankLevelsService", [->
  service =
    hourInMsec: 60*60*1000
    dayInMsec: 24*60*60*1000
    monthInMsec: 30*24*60*60*1000

    eighthour: 0.33 # is this ok
    oneday: 1
    fivedays: 5
    threeweeks: 7*3
    threemonths: 30*3
    ninemonths: 30*9
    totalLevelsExcludeUnstarted: 7

  # TODO: Clean up maximumReviewInterval and maximumReviewInterval etc. Might not need it anymore.
  _.assignIn service,   
    levels: [
      intLevel: 0
      slug: "unstarted"
      minLevel: 0.0
    ,
      intLevel: 0
      name: "js.memory_bank.level_new.name"
      slug: "level_new"
      locked: false
      minimumReviewInterval: 0
      maximumReviewInterval: 8*service.hourInMsec
      minDaysPermanence: 0
      maxDaysPermanence: service.eighthour
      minLevel: 0.0
    ,
      intLevel: 0.3
      name: "js.memory_bank.level_building.name"
      slug: "level_building"
      locked: false
      minimumReviewInterval: 8*service.hourInMsec
      maximumReviewInterval: 1*service.dayInMsec
      minDaysPermanence: service.eighthour
      maxDaysPermanence: service.oneday
      minLevel: 0.3
    ,
      intLevel: 1
      name: "js.memory_bank.level_1.name"
      slug: "level_1"
      locked: false
      minimumReviewInterval: 1*service.dayInMsec
      maximumReviewInterval: 5*service.dayInMsec
      minDaysPermanence: service.oneday
      maxDaysPermanence: service.fivedays
      minLevel: 1.0
    ,
      intLevel: 2
      name: "js.memory_bank.level_2.name"
      slug: "level_2"
      locked: true
      minimumReviewInterval: 5*service.dayInMsec
      maximumReviewInterval: 21*service.dayInMsec
      minDaysPermanence: service.fivedays
      maxDaysPermanence: service.threeweeks
      minLevel: 2.0
    ,
      intLevel: 3
      name: "js.memory_bank.level_3.name"
      slug: "level_3"
      locked: true
      minimumReviewInterval: 21*service.dayInMsec
      maximumReviewInterval: 3*service.monthInMsec
      minDaysPermanence: service.threeweeks
      maxDaysPermanence: service.threemonths
      minLevel: 3.0
    ,
      intLevel: 4
      name: "js.memory_bank.level_4.name"
      slug: "level_4"
      locked: true
      minimumReviewInterval: 3*service.monthInMsec
      maximumReviewInterval: 9*service.monthInMsec
      minDaysPermanence: service.threemonths
      maxDaysPermanence: service.ninemonths
      minLevel: 4.0
    ,
      intLevel: 5
      name: "js.memory_bank.level_master.name"
      slug: "mastered"
      locked: true
      minimumReviewInterval: 9*service.monthInMsec
      minDaysPermanence: service.ninemonths
      minLevel: 5.0
    ]

    getLevelFromPermanence: (review_interval) ->
      if _.isNull(review_interval)
        _.first(service.levels)
      else
        level = _.find service.levels, (level) ->
          review_interval >= level.minimumReviewInterval && review_interval < level.maximumReviewInterval
        level ||= _.last(service.levels)

    binContinuousLevel: (checkpoint_level, review_interval) ->
      if _.isUndefined(review_interval) || (review_interval == 0)
        _.first(service.levels)
      else
        levels = service.levels.slice().reverse()
        _.find levels, (level) ->
          checkpoint_level >= level.minLevel

    slugLevel: (checkpoint_level) ->
      if _.isUndefined(checkpoint_level) || (checkpoint_level == 0)
        _.first(service.levels).slug
      else
        levels = service.levels.slice().reverse()
        resultLevel = _.find levels, (level) ->
          checkpoint_level >= level.minLevel
        resultLevel.slug

  service
])
