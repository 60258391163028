siteNavigationApp.controller('topNavigationCtrl', ['$scope', '$routeParams', '$filter', 'DebugHelper', 'localStorageService', 'UserManager', 'SiteNavigationService', 'myPartnersService', 'productService', 'FreeTrialModalService', ($scope, $routeParams, $filter, DebugHelper, localStorageService, UserManager, SiteNavigationService, myPartnersService, productService, FreeTrialModalService) ->
  DebugHelper.register("topNavigationCtrl", $scope)

  _.assignIn $scope, {
    myPartnersService: myPartnersService
    UserManager: UserManager
    params: $routeParams
    routes: Packs.iKnowRoutes
    email: ""
    showPartnerSwitcher: false
    dropdown: {}
    urls: {}
    stateUI: {}

    initNavigationUrls: ->
      if $scope.currentProduct == 'learn' || !(myPartnersService.currentPlusPartner || myPartnersService.currentProPartner)
        myPartnersService.chooseProduct('basic')

      if myPartnersService.currentProPartner
        $scope.urls.proAdmin   = Packs.iKnowRoutes.v3_partner_dashboard_path(myPartnersService.currentProPartner.attributes.slug)
        $scope.urls.proCourses = Packs.iKnowRoutes.v3_courses_path(partnerId: myPartnersService.currentProPartner.id)
        $scope.urls.proCreate  = Packs.iKnowRoutes.v2_sets_path(partnerId: myPartnersService.currentProPartner.id)

      $scope.urls.basicLearn  = Packs.iKnowRoutes.v3_dashboard_path()
      $scope.urls.basicCreate = Packs.iKnowRoutes.v2_sets_path(partnerId: "")

      if myPartnersService.currentPlusPartner
        $scope.urls.plusCourses = Packs.iKnowRoutes.v3_courses_path(partnerId: myPartnersService.currentPlusPartner.id)

    setContext: (contextType, contextId) ->
      switch contextType
        when "courses"
          if myPartnersService.currentProPartner
            $scope.urls.proCourses = Packs.iKnowRoutes.v3_course_path(contextId, partnerId: myPartnersService.currentProPartner.id)
          if myPartnersService.currentPlusPartner
            $scope.urls.plusCourses = Packs.iKnowRoutes.v3_course_path(contextId, partnerId: myPartnersService.currentPlusPartner.id)
        when "sets", "set"
          $scope.urls.basicCreate = Packs.iKnowRoutes.v2_create_set_path(contextId, partnerId: "")
          if myPartnersService.currentProPartner
            $scope.urls.proCreate  = Packs.iKnowRoutes.v2_create_set_path(contextId, partnerId: myPartnersService.currentProPartner.id)
        when "series"
          $scope.urls.basicCreate = Packs.iKnowRoutes.v2_create_series_path(contextId, partnerId: "")
          if myPartnersService.currentProPartner
            $scope.urls.proCreate  = Packs.iKnowRoutes.v2_create_series_path(contextId, partnerId: myPartnersService.currentProPartner.id)
        else
          # NOOP - URLs already set by initNavigationUrls are correct

    expandMobileLeftNav: () ->
      console.log("broadcasting toggleLeftNavMobile")
      $scope.$root.$broadcast("toggleLeftNavMobile") # is this bad?

    initClassAndProduct: () ->
      $scope.currentProduct = productService.currentProduct

      $scope.initNavigationUrls()

      if myPartnersService.currentProductPartner
        $scope.stateUI.expireDays = myPartnersService.daysUntilExpiration(myPartnersService.currentProductPartner)

      switch $scope.currentProduct
        when 'cerego_admin'
          $scope.productTitle = 'js.top_nav.admin'
          $scope.ceregoIconClass = "cerego-basic-icon"
          $scope.productTextClass = "font-product-basic"
        when 'pro_admin', 'groups'
          $scope.productTitle = "js.top_nav.admin"
          $scope.ceregoIconClass = "cerego-pro-icon"
          $scope.productTextClass = "font-product-pro"
        when 'courses'
          if myPartnersService.currentProductPartner?.attributes['account-type'] == 'pro'
            $scope.productTitle = "js.top_nav.courses"
            $scope.ceregoIconClass = "cerego-pro-icon"
            $scope.productTextClass = "font-product-pro"
          else
            $scope.ceregoIconClass = "cerego-plus-icon"
            $scope.productTitle = "js.top_nav.courses"
            $scope.productTextClass = "font-product-plus"
        when 'create'
          if myPartnersService.currentProductPartner && myPartnersService.currentProductPartner.attributes['account-type'] == 'pro'
            $scope.productTitle = "js.top_nav.create"
            $scope.ceregoIconClass = "cerego-pro-icon"
            $scope.productTextClass = "font-product-pro"
          else
            $scope.productTitle = "js.top_nav.create"
            $scope.ceregoIconClass = "cerego-basic-icon"
            $scope.productTextClass = "font-product-basic"
        else
          $scope.productTitle = "js.top_nav.learn"
          $scope.ceregoIconClass = "cerego-basic-icon"
          $scope.productTextClass = "font-product-basic"

    buildNameString: () ->
      if !$scope.currentUser.attributes.name
        $scope.userString = $filter('translate')("js.top_nav.me")
      else
        $scope.userString = $filter('nameInitialHtml')($scope.currentUser.attributes.name)

    hideAllDropDowns: () ->
      $scope.dropdown.shouldShowProductDropDown = false
      $scope.dropdown.shouldShowUserDropDown = false

    toggleUserDropDown: ($event) ->
      $event.preventDefault()
      $event.stopPropagation()
      dropdownState = $scope.dropdown.shouldShowUserDropDown
      $scope.hideAllDropDowns()
      $scope.dropdown.shouldShowUserDropDown = !dropdownState

    toggleProductDropDown: ($event) ->
      $event.preventDefault()
      $event.stopPropagation()
      dropdownState = $scope.dropdown.shouldShowProductDropDown
      $scope.hideAllDropDowns()
      $scope.dropdown.shouldShowProductDropDown = !dropdownState

  }

  $scope.$on '$routeChangeSuccess', ($event, current, previous) ->
    myPartnersService.initialize().then () ->
      $scope.initClassAndProduct()

  UserManager.loadUser(true).then (user) ->
    $scope.currentUser = user
    if $scope.currentUser.attributes.email
      $scope.email = $scope.currentUser.attributes.email
    if $scope.currentUser.relationships.image.data
      $scope.showImage = true
    else
      $scope.showImage = false
      $scope.buildNameString()

    myPartnersService.initialize().then () ->
      $scope.initClassAndProduct()

      SiteNavigationService.$watch "context", (newVal, oldVal) ->
        $scope.setContext(SiteNavigationService.context?.type || SiteNavigationService.context_type, SiteNavigationService.context?.id)

      if FreeTrialModalService.shouldDisplayFreeTrialExpiredModal(user.attributes['all-partners-expired'], $scope.currentProduct, myPartnersService.currentProPartner)
        FreeTrialModalService.displayFreeTrialExpiredModal()

      # TODO: cache this in local storage
      myPartnersService.getPaginatedPartners().then (results) ->
        partitions = _.partition(results.data, (p) -> p.attributes['account-type'] == 'plus')
        plus_partners = partitions[0]
        pro_partners = partitions[1]
        $scope.shouldShowPartnerSwitcher = plus_partners.length > 1 || pro_partners.length > 1

  $scope.dismissDropdownHandler = (event) ->
    $scope.$apply ->
      $scope.dropdown = {}

  angular.element("body").click $scope.dismissDropdownHandler

  $scope.$on '$destroy', ->
    angular.element("body").unbind('click', $scope.dismissDropdownHandler)
])
