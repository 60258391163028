import Cookies from 'js-cookie';

export default class UserCookie {
  public locale?: string = null;
  public sessionExpiresAt: Date;
  private $cookies: ng.cookies.ICookiesService;
  private key = '_cerego_user';

  constructor($cookies) {
    const rawCookie = JSON.parse($cookies.get(this.key) || '{}');

    this.$cookies = $cookies;
    if (rawCookie.locale) {
      this.locale = rawCookie.locale;
    }
    if (rawCookie.session_expires_at) {
      this.sessionExpiresAt = new Date(rawCookie.session_expires_at);
    }
  }

  public setLocale(locale: string): UserCookie {
    this.locale = locale;
    return this;
  }

  public write() {
    return setCookie(
      this.key,
      JSON.stringify({
        locale: this.locale,
        session_expires_at: this.sessionExpiresAt
      })
    );
  }
}

export function setCookie(key, value) {
  const options = process.env.RAILS_ENV === 'development' ? {} : { sameSite: 'None', secure: true };

  return Cookies.set(key, value, options);
}
