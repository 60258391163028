signupApp.factory('ProPilotOffersResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_pro_pilot_offer_path(":id")
      params:
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_pro_pilot_offer_path(":id")
      params:
        id: "@id"
])
