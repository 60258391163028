memoryBank.factory('MemoryBankResource', ['$resource', ($resource) ->
  $resource "",{},
    # USER => MODULES
    userModules:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_goals_memory_bank_path(":user_id")
      params:
        user_id: "@user_id"

    # USER => AGGREGATE
    userAggregate:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_aggregate_memory_bank_path(":user_id")
      params:
        user_id: "@user_id"

    # USER + MODULE => ITEMS
    userSetFacets:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_set_items_memory_bank_path(":user_id", ":module_id")
      params:
        module_id: "@module_id"
        user_id: "@user_id"
    userSeriesFacets:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_series_items_memory_bank_path(":user_id", ":module_id")
      params:
        module_id: "@module_id"
        user_id: "@user_id"

    # USER + SERIES => SETS
    userSeriesGoals:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_series_goals_memory_bank_path(":user_id", ":module_id")
      params:
        module_id: "@module_id"
        user_id: "@user_id"

    # USER + SERIES => FACETS
    userSetAggregate:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_set_aggregate_memory_bank_path(":user_id", ":module_id")
      params:
        module_id: "@module_id"
        user_id: "@user_id"
    userSeriesAggregate:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_user_series_aggregate_memory_bank_path(":user_id", ":module_id")
      params:
        module_id: "@module_id"
        user_id: "@user_id"

    # SET => USERS
    setAggregate:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_set_aggregate_memory_bank_path(":module_id")
      params:
        module_id: "@module_id"

    # GROUP + USER => MODULES
    groupUserModules:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_group_user_goals_memory_bank_path(":group_id", ":user_id")
      params:
        group_id: "@group_id"
        user_id: "@user_id"

    # GROUP + MODULE => USERS
    groupSetUsers:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_group_set_items_memory_bank_path(":group_id", ":module_id")
      params:
        group_id: "@group_id"
        module_id: "@module_id"
    groupSeriesUsers:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_group_series_items_memory_bank_path(":group_id", ":module_id")
      params:
        group_id: "@group_id"
        module_id: "@module_id"

    # Standing Knowledge Bank
    groupGoalStanding:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_group_set_standing_memory_bank_path(":group_id", ":set_id")
      params:
        group_id: "@group_id"
        module_id: "@set_id"

    groupSeriesStanding:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_group_series_standing_memory_bank_path(":group_id", ":series_id")
      params:
        group_id: "@group_id"
        module_id: "@series_id"

])
