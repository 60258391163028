memoryBank.factory('FacetsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_facets_path()
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_facet_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_item_facets_path(":item_id")
      params:
        item_id: "@item_id"
    save:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_facet_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_facet_path(":id")
      params:
        id: "@id"
])
