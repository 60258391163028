siteNavigationApp.service('CourseContextService', ['$rootScope', '$routeParams', '$q', 'DebugHelper', 'SiteNavigationService', 'V3CoursesResource', 'V3ModelHelper', ($rootScope, $routeParams, $q, DebugHelper, SiteNavigationService, V3CoursesResource, V3ModelHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("CourseContextService", $scope)

  _.assignIn $scope,
    course: null
    deferred: null

    getCourseId: ->
      return $routeParams.course_id || $routeParams.id || -1 # TODO maybe use courseId everywhere?

    initialize: (checkForRefresh) ->
      # if we already had a course loaded from before but then we're switching to another one
      if $scope.course && $scope.course?.id?.toString() != $scope.getCourseId()
        $scope.resetCourse()

      # if some other page marked the service dirty because it modified assignments/users counts
      if checkForRefresh && $scope.refreshRequired
        $scope.resetCourse()

      $scope.fetchCourse().then ->
        if !$scope.course
          # TODO how to deal with this error?
          return
        SiteNavigationService.setContext($scope.course, 'course')

    resetCourse: ->
      $scope.course = null
      $scope.deferred = null

    # call this function after modifying something that could affect
    # the counts attached to a course object (i.e. assignments, users)
    markRefreshRequired: ->
      $scope.refreshRequired = true
      $scope.childRefreshRequired = true

    removeChildRefreshRequired: ->
      $scope.childRefreshRequired = false

    fetchCourse: ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        $scope.refreshRequired = false

        V3CoursesResource.get
          id: $scope.getCourseId()
          include: "partner,partner.image,partner.partner-library"
        , (success) ->
          $scope.course = V3ModelHelper.parse(success)
          $scope.deferred.resolve($scope.course)
        , $scope.deferred.reject
      $scope.deferred.promise

  $scope
])
