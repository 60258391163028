ceregoLayoutApp.controller('subscriptionsCtrl', ['$scope', '$routeParams', '$timeout', 'DebugHelper', 'SubscriptionsResource', ($scope, $routeParams, $timeout, DebugHelper, SubscriptionsResource) ->
  DebugHelper.register("subscriptionsCtrl", $scope)

  _.assignIn $scope,
    dirty: true
    routes: $routeParams

    setMessage: (message) ->
      $timeout.cancel($scope.interval) if $scope.interval
      $scope.message = message
      $scope.interval = $timeout ->
        $scope.message = null
      , 5000

    update: (group) ->
      $scope.dirty = true
      options = if group
        group_id: group.group_id
        email: group.email
      else if $routeParams.partner_id
        partner_id: $routeParams.partner_id
        email: $scope.subscriptions.email
      else
        email: $scope.subscriptions.email

      SubscriptionsResource.update
        token: $routeParams.token
      , options
      , (success) ->
        $scope.setMessage("js.subscriptions.update_success")
        $scope.dirty = false

    destroy: ->
      $scope.dirty = true
      SubscriptionsResource.delete
        token: $routeParams.token
      , (success) ->
        $scope.setMessage("js.subscriptions.unsubscribe_success")
        for key in $scope.subscriptionKeys
          $scope.subscriptions.email[key] = false
        $scope.dirty = false

    subscriptionKeys: ->

    keysFor: (group) ->
      _.keys(group.email)

  SubscriptionsResource.query
    token: $routeParams.token
  , (success) ->
    if $routeParams.partner_id
      $scope.subscriptions = success.response.partner_email_settings.filter (subscription) ->
        subscription.partner_id = $routeParams.partner_id
      $scope.subscriptions = $scope.subscriptions[0]
      $scope.subscriptionKeys = if _.isUndefined($scope.subscriptions) then [] else _.keys($scope.subscriptions.email).filter (subscription) ->
        !subscription.includes('push_')
    else
      $scope.subscriptions = success.response
      $scope.subscriptionKeys = _.keys($scope.subscriptions.email).filter (subscription) ->
        !subscription.includes('push_')

    $scope.dirty = false
])
