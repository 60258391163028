workloadCalculatorApp.directive('workloadReviewVisual', [->
  restrict: 'E'

  scope:
    data: "="
    yLabel: "@"

  templateUrl: Packs.iKnowRoutes.v2_template_path("workload_svg")

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("workloadReviewVisual", $scope)

    _.assignIn $scope,  {
      updateSVG: ->
        data = _.map _.values($scope.data), (tick, i) ->
          stackY = 0
          _.map ["5","4","3","2","1","b","n"], (level) ->
            level: level
            x: i
            y: tick[level] || 0
            stackY: (stackY += (tick[level] || 0))

        m = data.length
        return if m < 1
        n = data[0].length

        layout = d3.layout.stack()
        layers = layout(data)

        yStackMax = d3.max layers, (layer) ->
          d3.sum(_.map(layer,"y"))

        margin =
          top: 20
          right: 10
          bottom: 60
          left: 60

        width = $scope.element.width() - margin.left - margin.right
        height = $scope.element.height() - margin.top - margin.bottom

        $scope.xScale = d3.scale.ordinal()
          .domain(d3.range(m))
          .rangeRoundBands([0, width], .1)

        $scope.yScale = d3.scale.linear()
          .domain([0, yStackMax])
          .range([height, 0])

        colorScale =
          n: "#333333"
          b: "#ffffff"
          1: "#2294f2"
          2: "#9347BE"
          3: "#e73e79"
          4: "#EEB100"
          5: "#7ab72d"

        strokeScale =
          n: "#ffffff"
          b: "#ffffff"
          1: "#2294f2"
          2: "#9347BE"
          3: "#e73e79"
          4: "#EEB100"
          5: "#7ab72d"

        numTicks = Math.max(3, Math.round($scope.xScale.rangeExtent()[1]  / 40))

        xAxis = d3.svg.axis()
          .scale($scope.xScale)
          .tickValues(_.range(0, m, Math.round(m / numTicks)))
          .tickSize(5)
          .tickPadding(6)
          .orient("bottom")

        yAxis = d3.svg.axis()
          .scale($scope.yScale)
          .ticks(10)
          .tickSize(5)
          .tickPadding(6)
          .orient("left")

        yGrid = d3.svg.axis()
          .scale($scope.yScale)
          .ticks(10)
          .tickSize(- width, 0, 0)
          .tickPadding(6)
          .tickFormat("")
          .orient("left")

        $scope.svg.selectAll(".axis.x")
          .attr("transform", "translate(" + margin.left + "," + (height + margin.top) + ")")
          .call(xAxis)

        $scope.svg.selectAll(".axis.y")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
          .call(yAxis)


        $scope.svg.selectAll(".grid.y")
          .attr("stroke-dasharray", "5,5")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")")
          .call(yGrid)

        $scope.svg.selectAll(".axisLabel.x")
          .attr("text-anchor", "middle")
          .attr("transform", "translate(" + (margin.left + width / 2) + "," + (height + margin.top + 40) + ")")

        $scope.svg.selectAll(".axisLabel.y text").text($scope.yLabel)

        $scope.svg.selectAll(".axisLabel.y")
          .attr("text-anchor", "middle")
          .attr("transform", "translate(20," + (margin.top + height / 2) + ")rotate(-90)")

        container = $scope.svg.selectAll(".chart").attr("transform", "translate(" + margin.left + "," + margin.top + ")")
        layer = container.selectAll(".layer").data(layers, (d,i) -> i)

        layer.enter()
          .append("g")
          .attr("class", "layer")

        rect = layer.selectAll("rect").data((d) ->
          d
        , (d) ->
          d.level + "" + d.x
        )

        rect.enter().append("rect")

        rect.attr("x", (d) -> $scope.xScale(d.x))
          .attr("y", (d) -> $scope.yScale(d.stackY))
          .attr("width", $scope.xScale.rangeBand())
          .attr("height", (d) -> $scope.yScale(d.stackY - d.y) - $scope.yScale(d.stackY))
          .style("fill", (d) -> colorScale[d.level])
          .style("stroke", (d) -> strokeScale[d.level])
          .style("stroke-width", 1)

        rect.exit().remove()
        layer.exit().remove()
    }
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element
    $scope.svg = d3.select(element.find("svg")[0])

    $scope.$watch "data", (newVal, oldVal) ->
      $scope.updateSVG()
    , true
])
