memoryBank.factory("MemoryStorage", ['$rootScope', '$q', 'MemoryBankResource', ($rootScope, $q, MemoryBankResource) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["MemoryStorage"] = $scope

  _.assignIn $scope,
    cache: {}

    reset: ->
      $scope.cache = {}

    cacheKey: (constraints) ->
      key = ""
      key += "aggregate:" if constraints.aggregate
      key += "group:" + constraints.group_id + ":" if constraints.group_id
      key += "my_group:" + constraints.my_group_id + ":" if constraints.my_group_id
      key += "user:" + constraints.user_id + ":" if constraints.user_id
      key += "lastStudyTime:" + constraints.lastStudyTime if constraints.lastStudyTime
      key += constraints.module_type + ":" + constraints.module_id + ":" if constraints.module_id
      key

    loadMemAgg: (user_guid, collection_ids) ->
      $scope.deferred = $q.defer()
      MemoryBankResource.userMemAgg { user_id: user_guid, "collection_ids[]" : collection_ids }
      , (success) ->
        $scope.deferred.resolve(success.response)

      $scope.deferred.promise

    getLastConstraints: ->
      $scope.lastConstraints

    setLastConstraints: (constraints) ->
      $scope.lastConstraints = constraints

    load: (constraints) ->
      error = null
      type = null
      resource = null

      if constraints.group_id && !constraints.need_set_score
        # Group Knowledge Bank
        if constraints.module_id
          if constraints.user_id
            type = "facets"
            constraints.group_id = null # specifying group leads to 403 on backend !?
            if constraints.module_type == "set"
              resource = "userSetFacets"
            else
              resource = "userSeriesFacets"
          else
            type = "users"
            if constraints.module_type == "set"
              resource = "groupSetUsers"
            else
              resource = "groupSeriesUsers"
        else if constraints.user_id
          type = "modules"
          resource = "groupUserModules"
        else
          error = "Group knowledge bank requires either module or user"
          resource = null
      else if constraints.user_id
        # "My" Knowledge Bank
        if constraints.aggregate
          if constraints.module_id
            type = "modules"
            if constraints.module_type == "set"
              resource = "userSetAggregate"
            else
              resource = "userSeriesAggregate"
          else
            type = "users"
            resource = "userAggregate"
        else
          if constraints.module_id
            if constraints.module_type == "seriesGoals"
              type = "modules"
              resource = "userSeriesGoals"
            else
              type = "facets"
              if constraints.module_type == "set"
                resource = "userSetFacets"
              else
                resource = "userSeriesFacets"
          else
            type = "modules"
            resource = "userModules"
      else if constraints.module_id && constraints.aggregate
        # Admin set aggregate with users (like group)
        type = "users"
        resource = "setAggregate"
      else
        error = "User knowledge bank requires user to be signed in"
        null

      key = $scope.cacheKey(constraints)
      cache = $scope.cache[key] ||= {}

      $scope.setLastConstraints(constraints)

      if !cache.deferred
        cache.deferred = $q.defer()

        params = _.pick(constraints,["group_id", "module_id", "user_id"])
        params.group_id ||= constraints.my_group_id

        if $scope.loading
          $scope.loading.resolve()
        $scope.loading = $q.defer()

        if resource
          MemoryBankResource[resource] params
          , timeout: $scope.loading.promise
          , (success) ->
            cache.data = success.response
            cache.deferred.resolve
              type: type
              memories: cache.data
              message: success.meta.message
          , cache.deferred.reject
        else
          cache.deferred.reject(error)

      cache.deferred.promise
])
