siteNavigationApp.service('myPartnersService', ['$q', '$rootScope', '$location', '$routeParams', 'localStorageService', 'V3PartnersResource', 'UserManager', 'DebugHelper', 'productService', 'CourseContextService', 'moduleContextService', 'MyProfileResource', 'V3ModelHelper', ($q, $rootScope, $location, $routeParams, localStorageService, V3PartnersResource, UserManager, DebugHelper, productService, CourseContextService, moduleContextService, MyProfileResource, V3ModelHelper) ->
  $scope = $rootScope.$new()

  DebugHelper.register("myPartnersService", $scope)

  VERSION = "1.0.9"

  fetchFromLocalStorage = (key) ->
    try
      $scope[key] = angular.fromJson(localStorageService.get(key))
    catch
      localStorageService.remove(key)
      null

  persistInLocalStorage = (key) ->
    try
      localStorageService.set(key, angular.toJson($scope[key]))
    catch
      null

  finishInitialization = () ->
    $scope.myPartnersServiceInitialized = VERSION
    persistInLocalStorage("myPartnersServiceInitialized")

    switch productService.currentProduct
      when 'pro_admin', 'groups'
        $scope.chooseProduct('pro')
      when 'courses'
        unless $scope.currentProductPartner
          if $scope.currentProPartner
            $scope.chooseProduct('pro')
          else
            $scope.chooseProduct('plus')

    $scope.deferred.resolve($scope.currentProductPartner)
    
  _.assignIn $scope,
    currentProPartner: null
    currentPlusPartner: null
    currentProductPartner: null
    deferredLoadPartner: {}

    isBBC: ->
      _.includes([831, 2574, 4493], $scope.currentProPartnerId)

    chooseProduct: (product) ->
      $scope.currentProductPartner = switch product
        when "pro"
          $scope.currentProPartner
        when "plus"
          $scope.currentPlusPartner
        else
          null

    daysUntilExpiration: (partner) ->
      if partner?.attributes["expires-at"]
        today = moment().startOf('day')
        expiresAt = moment(partner.attributes["expires-at"])
        expiresInDays = expiresAt.diff(today, 'days')
        expiresInDays
      else
        null

    chooseProductPartner: (partner) ->
      $scope.currentProductPartner = partner
      $rootScope.$broadcast "siteNav:updateUI"
      if partner?.attributes['account-type'] == 'plus'
        $scope.choosePlusPartner(partner)
      else
        $scope.chooseProPartner(partner)

    chooseProPartner: (partner) ->
      $scope.currentProPartner = partner
      $scope.currentProPartnerId = partner.id
      persistInLocalStorage("currentProPartnerId")

    choosePlusPartner: (partner) ->
      $scope.currentPlusPartner = partner

    showPartnerModuleDetails: ->
      $scope.currentProductPartner && productService.currentProduct == 'create'

    getPartnerIdFromRoute: () ->
      # URL in form of /partners/xxx
      if $routeParams.hasOwnProperty("partner_id") && !window.location.href.match("learning_library")
        return $routeParams.partner_id
      # URL in form of /?partnerId=xxx
      else if $routeParams.hasOwnProperty("partnerId") && $routeParams.partnerId
        return $routeParams.partnerId
      return false

    # loads previous partner if id was in localStorage
    loadPreviousProPartner: () ->
      unless $scope.deferredLoadPrevious
        $scope.deferredLoadPrevious = $q.defer()
        $scope.myPartnersServiceInitialized = fetchFromLocalStorage("myPartnersServiceInitialized")
        # only load if our front end is up to date and we have a saved currentProPartnerId in localStorage
        if $scope.myPartnersServiceInitialized == VERSION && fetchFromLocalStorage("currentProPartnerId")
          $scope.loadPartner($scope.currentProPartnerId).then (partner) ->
            $scope.chooseProPartner(partner) if partner
            $scope.deferredLoadPrevious.resolve()
        else
          $scope.deferredLoadPrevious.resolve()
      $scope.deferredLoadPrevious.promise

    # returns partner already loaded in $scope or UserManager
    loadedMatchingPartner: (partnerId) ->
      if partnerId == UserManager.currentUser?.relationships?["pro-partner"].data?.id || partnerId == UserManager.currentUser?.relationships?["pro-partner"].data?.slug
        return UserManager.currentUser.relationships["pro-partner"].data
      else if partnerId == UserManager.currentUser?.relationships?["plus-partner"].data?.id || partnerId == UserManager.currentUser?.relationships?["plus-partner"].data?.slug
        return UserManager.currentUser.relationships["plus-partner"].data
      else if partnerId == $scope.currentProPartner?.id || partnerId == $scope.currentProPartner?.attributes?.slug
        return $scope.currentProPartner
      else if partnerId == $scope.currentPlusPartner?.id || partnerId == $scope.currentPlusPartner?.attributes?.slug
        return $scope.currentPlusPartner
      return false

    loadPartner: (partnerId) ->
      unless $scope.deferredLoadPartner[partnerId]
        $scope.deferredLoadPartner[partnerId] = $q.defer()
        #use matching partner if already loaded
        loadedPartner = $scope.loadedMatchingPartner(partnerId)
        if loadedPartner
          $scope.deferredLoadPartner[partnerId].resolve(loadedPartner)
        else
          #otherwise, fetch partner
          V3PartnersResource.get
            id: partnerId,
            include: "image,account.plan,partner-library,parent,partner-metadata"
          , (success) ->
            partner = V3ModelHelper.parse(success)
            # only choose as product if they have a role on partner
            if partner.meta?["role"]
              $scope.deferredLoadPartner[partnerId].resolve(partner)
            else
              $scope.deferredLoadPartner[partnerId].resolve()
          , (failure) ->
            $scope.deferredLoadPartner[partnerId].resolve()
      $scope.deferredLoadPartner[partnerId].promise

    loadCourse: () ->
      CourseContextService.initialize().then (success) ->
        if CourseContextService.course?.meta?["can-edit"]
          $scope.loadPartner(CourseContextService.course.relationships.partner.data.id).then (partner) ->
            $scope.chooseProductPartner(partner)
            finishInitialization()
        else
          $scope.chooseProduct('basic')
          finishInitialization()

    loadModule: () ->
      moduleContextService.initialize().then (module) ->
        if module?.permissions.editable
          if module.partner_id?
            # load create as partner
            $scope.loadPartner(module.partner_id).then (partner) ->
              $scope.chooseProductPartner(partner)
              finishInitialization()
          else
            # load personal create
            $scope.chooseProduct('create')
            finishInitialization()
        # user manually loaded create on a set they dont have permission for
        else
          finishInitialization()

    loadDefault: (user) ->
      if user
        # set their primary partner unless they already have a different pro partner loaded from localStorage
        if user.relationships["pro-partner"].data && !$scope.currentProPartner
          $scope.chooseProPartner(user.relationships["pro-partner"].data, user)
        # on first load, default to partner context in create
        if productService.currentProduct == 'create' && ($scope.currentPlusPartner || $scope.currentProPartner)
          $scope.chooseProductPartner($scope.currentPlusPartner || $scope.currentProPartner)
        finishInitialization()
      else
        finishInitialization()

    initialize: ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        UserManager.loadUser().then (user) ->
          if user
            productService.initialize().then () ->
              # users only have one plus-partner, always set this
              if user.relationships?["plus-partner"].data
                $scope.choosePlusPartner(user.relationships["plus-partner"].data)
              # always load this as a backup, so it will appear in upper left dropdown
              $scope.loadPreviousProPartner().then () ->
                partnerId = $scope.getPartnerIdFromRoute()
                # URL has partner Id
                if partnerId
                  $scope.loadPartner(partnerId).then (partner) ->
                    $scope.chooseProductPartner(partner)
                    finishInitialization()
                # URL in form of /courses/yyy
                else if $routeParams.course_id
                  $scope.loadCourse()
                # URL is /create/sets/zzz or /create/series/zzz
                else if ($routeParams.hasOwnProperty("set_id") || $routeParams.hasOwnProperty("series_id")) && _.includes($location.path(), "create")
                  $scope.loadModule()
                #we specifically passed in no partner, choose basic
                else if $routeParams.hasOwnProperty("partnerId")
                  $scope.chooseProduct('basic')
                  finishInitialization()
                else
                  $scope.loadDefault(user)
          else
            $scope.deferred.resolve({})

      $scope.deferred.promise

    # PAGINATION LOGIC BELOW
    paginatedDeferred: {}

    # Hit v3 endpoint. Should really be in its own v3 $scope, but uses the same helpers + thinking
    # at the point we migrate everything over to this we can move it and kill the v2 $scope.
    getPaginatedPartners: (page_number, order_by, query)-> #use an options hash
      page_number = page_number || 1
      if ($scope.paginationOrder != order_by || $scope.paginationQuery != query)
        $scope.paginatedDeferred = {}
      $scope.paginationOrder = order_by
      $scope.paginationQuery = query

      unless $scope.paginatedDeferred[page_number]
        $scope.paginatedDeferred[page_number] = $q.defer()

        UserManager.loadUser().then (user) ->
          if user
            V3PartnersResource.my_partners
              "page[number]": page_number,
              sort: order_by,
              query: query,
              include: "image",
              "filter[exclude_expired]": 1
            , (result) ->
              $scope.paginated_partners = V3ModelHelper.parse(result)
              if $scope.paginated_partners.length == 0 && !$scope.currentPlusPartner
                $scope.chooseProduct('basic')
              $scope.paginatedDeferred[page_number].resolve(result) #not good enough to resolve with result.data -- need result.links in controller as well
          else
            $scope.chooseProduct('basic')
            $scope.paginatedDeferred[page_number].resolve([])

      $scope.paginatedDeferred[page_number].promise

  $scope
])
