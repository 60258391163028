ceregoLayoutApp.controller('layoutCtrl', ['$scope', '$routeParams', '$cookies', '$window', '$compile', '$mdToast', '$location', 'DebugHelper', 'UserManager', 'productService', 'toastHelper', '$q', 'ModalService', 'SiteNavigationService', ($scope, $routeParams, $cookies, $window, $compile, $mdToast, $location, DebugHelper, UserManager, productService, toastHelper, $q, ModalService, SiteNavigationService) ->
  DebugHelper.register("layoutCtrl", $scope)
  _.assignIn $scope,
    showSpinnerLoader: true
    loading: true
    layout: {}
    userLoading: true
    UserManager: UserManager

    returnHome: () ->
      $window.location.href = Packs.iKnowRoutes.v3_root_path()

    showModal: (name, options) ->
      $scope.modalOptions = options
      modal = angular.element(document.createElement('v3-modal')).attr("modal-name", name).attr("modal-options", "modalOptions")
      el = $compile(modal)($scope)
      angular.element(document.body).append(el)
      false

    showToast: toastHelper.showToast

  UserManager.loadUser().then (currentUser) ->
    $scope.userLoading = false
    $scope.layout.user = currentUser
    $scope.layout.signedIn = !!currentUser
    $scope.layout.showSignin = !$scope.layout.showSignup && !$scope.layout.signedIn

  $scope.$on "moduleDropDown:remixProcessing", (event) ->
    $scope.showSpinnerLoader = true

  $scope.$on "moduleDropDown:processingError", (event) ->
    $scope.showSpinnerLoader = false

  $scope.$on '$routeChangeSuccess', ($event, current, previous) ->
    productService.setCurrentProduct(current.locals.$product) #handle fallbacks in service
    $scope.showSpinnerLoader = false
    $scope.layout.header = current.locals.$headerTheme || "default"
    $scope.layout.hideFooter = $location.path() == Packs.iKnowRoutes.lti_inactivity_logout_v3_static_path()
    $scope.layout.isLTI ||= !!current.locals.$isLTI || $routeParams.embed || angular.element("body").hasClass("lti")
    $scope.layout.isMHE ||= $routeParams.embed == "mhe"
    $scope.layout.fixedFooter = current.locals.$fixedFooter || false
    $scope.layout.showSignup = !!current.locals.$showSignup
    $scope.layout.showSignin = false  if $scope.layout.showSignup
    $scope.layout.contentModifierClass = current.locals.$contentModifierClass
    isV4Embed = SiteNavigationService.isV4Embed()
    $scope.layout.isV4Embed = isV4Embed
    theme = "theme-" + $scope.layout.header
    if $scope.layout.isLTI
      theme= "theme-lti"
    if $scope.layout.isV4Embed
      theme = "theme-v4-embed"
    $scope.layout.theme = theme

    if $scope.layout.user
      partner = $scope.layout.user.relationships['primary-partner']
      partner_id = partner?.data?.id || 0
      segmentation = UserManager.getSegmentation($scope.layout.user)
      role = UserManager.getRole($scope.layout.user)
      unless _.includes(process.env.RAILS_ENV, 'govcloud')
        UserManager.pingSegment(partner_id, segmentation, role, $scope.layout.user)

    if isV4Embed
      window.parent.postMessage({ pathname: window.location.pathname }, '*')
    $scope.loading = false
])
