memoryBank.service("difficultyVisual", ['$rootScope', 'hcHelper', ($rootScope, hcHelper) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["difficultyVisual"] = $scope

  HEADER_HEIGHT = 60

  _.assignIn $scope,
    updateAxis: (selection) ->

    updateScales: (svgDimensions, data, radius, levels) ->
      $scope.bb =
        top: HEADER_HEIGHT + radius
        bottom: svgDimensions.y - radius
        left: 0
        right: svgDimensions.x

      $scope.shouldPerformCollisions = !$scope.isHC

      bucket = ($scope.bb.right - $scope.bb.left) / 4.0
      $scope.xScale = d3.scale.quantize().domain([0, 1, 3, 4]).range([bucket*0.5, bucket*1.5, bucket*2.5, bucket*3.5])
      $scope.yScale = d3.scale.quantile().range([($scope.bb.top + $scope.bb.bottom) * 0.5])

      if $scope.isHC
        aggregate = hcHelper.aggregate(data, "difficulty_bucket", true)
        $scope.sums = aggregate.sums

        $scope.data = _.sortBy(_.flatten(_.map(aggregate.grid, (bin, bucket) ->
          _.map bin, (data, level_slug) ->
            id: bucket + ":" + level_slug
            orb_type: "difficultyOrb"
            count: data.length
            data: data
            difficulty_bucket: parseInt(bucket)
            level_slug: level_slug
            target:
              x: $scope.xScale(parseInt(bucket))
              y: $scope.yScale(1)
        )), "level")

        $scope.areaScale = d3.scale.linear().domain([0, aggregate.max]).range([0, Math.pow(Math.min(bucket, $scope.bb.bottom - $scope.bb.top) / 2 - 10, 2)])

    setSourcePosition: (parent) ->
      (selection) ->
        unless $scope.isHC
          selection.each (d) ->
            offset = 200
            if parent
              d.x = $scope.xScale(parent.difficulty_bucket)
              d.y = $scope.yScale(1)
            else
              d.x = $scope.xScale(1)
              d.y = $scope.yScale(1)
              d.x += Math.random() * offset * 2 - offset
              d.y += Math.random() * offset * 2 - offset

    setTargetPosition: (selection) ->
      selection.each (d) ->
        d.target =
          x: $scope.xScale(d.difficulty_bucket)
          y: $scope.yScale(1)

    shouldCollide: (a, b) ->
      a.difficulty_bucket == b.difficulty_bucket

    collisionForce: (l, r, alpha) ->
      l = _.max([l, .1 * r])
      (l - r) / l * .5

    createNode: (selection) ->
      selection.classed "difficulty-orb", true
      selection.append "title"
      .text (d) -> d.count + " in " + d.level_slug

      selection.append "rect"
      .attr
        x: (d) -> 0 - Math.sqrt($scope.areaScale(d.count + $scope.sums[d.difficulty_bucket][d.level_slug]))
        y: (d) -> 0 - Math.sqrt($scope.areaScale(d.count + $scope.sums[d.difficulty_bucket][d.level_slug]))
        width: (d) -> 2 * Math.sqrt($scope.areaScale(d.count + $scope.sums[d.difficulty_bucket][d.level_slug]))
        height: (d) -> 2 * Math.sqrt($scope.areaScale(d.count + $scope.sums[d.difficulty_bucket][d.level_slug]))

    hcAnimate: (selection) ->
      delayMult = 100

      selection.attr
        transform: (d) ->
          "translate(" + [d.x, d.y] + ") scale(0)"
      .style("fill-opacity", 0)

      selection.transition()
      .delay (d) ->
        switch d.level_slug
          when "mastered"
            delayMult * 0
          when "level_4"
            delayMult * 1
          when "level_3"
            delayMult * 2
          when "level_2"
            delayMult * 3
          else
            delayMult * 4
      .duration(600)
      .style("fill-opacity", 1)
      .attr
        transform: (d) ->
          "translate(" + [d.x, d.y] + ") scale(1)"

  $scope
])
