require('jquery-hoverintent')

window.memoryBank = angular.module("cerego.memoryBank", [
  "cerego.common"
  "angularMoment"
])
.run(['amMoment', (amMoment) ->
  locale = angular.element("body").data("locale") || DEFAULT_LOCALE
  amMoment.changeLocale(locale)
  moment.relativeTimeThreshold("s", 60)
  moment.relativeTimeThreshold("m", 60)
  moment.relativeTimeThreshold("h", 24)
  moment.relativeTimeThreshold("d", 28)
  moment.relativeTimeThreshold("M", 12)
])

# replace these with explicit import
req = require.context('./memory-bank/resources', false, /\.js$/)
req.keys().forEach(req)

require('./memory-bank/memory-bank-asset-helper.js')
require('./memory-bank/memory-storage.js')
require('./memory-bank/hc-helper.js')

require('./memory-bank/progress-visual.js')
require('./memory-bank/difficulty-visual.js')
require('./memory-bank/timeline-visual.js')
require('./memory-bank/total-time-visual.js')

require('./memory-bank/memory-bank-visual.js')
require('./memory-bank/memory-bank-level.js')
require('./memory-bank/memory-bank.js')
require('./memory-bank/memory-bank-demo.js')
