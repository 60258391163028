import defaultSmallUserImage from 'images/default/small/user.jpg'

siteNavigationApp.controller("partnerSwitcherModal", ['$scope', '$location', '$filter', '$timeout', '$document', '$window', 'myPartnersService', 'DebugHelper', 'localStorageService', 'SiteNavigationService', 'productService', 'V3ModelHelper', 'UserManager', ($scope, $location, $filter, $timeout, $document, $window, myPartnersService, DebugHelper, localStorageService, SiteNavigationService, productService, V3ModelHelper, UserManager) ->
  DebugHelper.register("partnerSwitcher", $scope)

  _.assignIn $scope,
    stateUI:
      query: ""
      direction: "desc"

    myPartnersService: myPartnersService
    dropdown: {}
    currentPage: 1
    partnerOrders: [
      name: "js.filters.name"
      direction:
        desc: "name"
        asc: "-name"
      default_direction: "desc"
    ]
    defaultUserImage: defaultSmallUserImage

    toggleSortDirection: ($event) ->
        $event.preventDefault()
        $scope.stateUI.direction = if $scope.stateUI.direction == 'asc' then 'desc' else 'asc'
        $scope.getPage(1)

    getPage: (num) ->
      myPartnersService.getPaginatedPartners(num, $scope.order_by.direction[$scope.stateUI.direction], $scope.stateUI.query).then (result) ->
        $scope.currentPage = num
        $scope.totalCount = result.meta['total-count']
        $scope.partners = V3ModelHelper.parse(result)
        $scope.nextPageLinks = result.links

    currentPartnerImage: () ->
      $scope.imageForPartner(myPartnersService.currentProductPartner)

    imageForPartner: (partner) ->
      partner?.relationships?.image?.data?.attributes

    setCurrentPartner: ($event, partner) ->
      $event.preventDefault()
      if partner
        hadActivePartner = !!myPartnersService.currentProductPartner

        UserManager.updatePrimaryPartner(partner.id).then () ->
          myPartnersService.chooseProductPartner(partner)
          myPartnersService.chooseProduct('basic') unless hadActivePartner
          $scope.loadPage(partner)
          $scope.closeModal()

    loadPage: (partner) ->
      # TODO: revisit this logic, it leads to some weird UX
      if productService.currentProduct == "courses"
        $window.location.href = Packs.iKnowRoutes.v3_courses_path({partnerId: partner.id})
      else if productService.currentProduct == "create"
        $window.location.href = Packs.iKnowRoutes.v2_sets_path({partnerId: partner.id})
      else if productService.currentProduct == "pro_admin" || productService.currentProduct == "groups"
        $window.location.href = Packs.iKnowRoutes.v3_partner_dashboard_path(partner.attributes.slug)
      else
        $window.location.href = Packs.iKnowRoutes.v3_dashboard_path()

  $scope.order_by = $scope.partnerOrders[0]

  myPartnersService.initialize().then () ->
    $scope.getPage(1)
    $timeout () ->
      angular.element("#partner-search-input").focus()
])
