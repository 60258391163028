import coursePlaceholderImage from 'images/v2/memory-bank/courses_placeholder.png'
import levelNewAvatarImage from 'images/v2/memory-bank/levelnew-avatar.png'
import levelBuildingAvatarImage from 'images/v2/memory-bank/levelbuilding-avatar.png'
import level1AvatarImage from 'images/v2/memory-bank/level1-avatar.png'
import level2AvatarImage from 'images/v2/memory-bank/level2-avatar.png'
import level3AvatarImage from 'images/v2/memory-bank/level3-avatar.png'
import level4AvatarImage from 'images/v2/memory-bank/level4-avatar.png'
import masteredAvatarImage from 'images/v2/memory-bank/mastery-avatar.png'

memoryBank.factory("MemoryBankAssetHelper", ['$rootScope', ($rootScope) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["MemoryBankAssetHelper"] = $scope

  _.assignIn $scope,
    defaultModuleOrb: () ->
      coursePlaceholderImage

    defaultUserOrb: (level_slug) ->
      switch level_slug
        when "level_new"
          levelNewAvatarImage
        when "level_building"
          levelBuildingAvatarImage
        when "level_1"
          level1AvatarImage
        when "level_2"
          level2AvatarImage
        when "level_3"
          level3AvatarImage
        when "level_4"
          level4AvatarImage
        when "mastered"
          masteredAvatarImage
])
