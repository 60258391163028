import UserCookie, { setCookie } from 'javascripts/v2/modules/core/user-cookie-helper'

siteNavigationApp.factory("UserManager", ['$rootScope', '$q', '$cookies', '$analytics', '$timeout', 'localStorageService', 'DebugHelper', 'Keepalive', 'MyProfileResource', 'V3ModelHelper', 'ModalService','$window', 'V3SessionResource', 'V3DeloitteAutoLogInResource', 'UsersResource', 'AmplitudeService', ($rootScope, $q, $cookies, $analytics, $timeout, localStorageService, DebugHelper, Keepalive, MyProfileResource, V3ModelHelper, ModalService, $window, V3SessionResource, V3DeloitteAutoLogInResource, UsersResource, AmplitudeService) ->
  $scope = $rootScope.$new()

  DebugHelper.register("UserManager", $scope)

  tryClearLocalStorage = ->
    try
      localStorageService.clearAll("UserManager")
    catch
      null

  _.assignIn $scope,
    # Tries to load the current user, return a promise for either the user or false when logged out
    currentUser:
      initialized: false

    loadDeloitteUser: (type) ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        if type == 'courses'
          V3DeloitteAutoLogInResource.courses {}
          , (success) ->
            $scope.currentUser = V3ModelHelper.parse(success)
            $scope.deferred.resolve($scope.currentUser)
        else if type == "learn"
          V3DeloitteAutoLogInResource.learn {}
          , (success) ->
            $scope.currentUser = V3ModelHelper.parse(success)
            $scope.deferred.resolve($scope.currentUser)
      $scope.deferred.promise

    signOutClicked: ($event) ->
      $event.preventDefault()
      $scope.signOut()

    signOut: () ->
      V3SessionResource.destroy {}
      , (success) ->
        $scope.resetUser()
        $window.location.href = Packs.iKnowRoutes.v3_signin_path()
      , (failure) ->
        console.error("User Sign Out fails")

    isAdmin: ->
      $scope.currentUser?.meta?["is-admin"]

    isCeregoInternal: ->
      $scope.currentUser?.relationships?['primary-partner']?.data?.id == 21

    showPrototype: ->
      $scope.isCeregoInternal()|| process.env.RAILS_ENV == 'development'

    isNewNav: ->
      $scope.currentUser.relationships?["primary-partner"]?.data?.attributes?["partner-settings"]?["show-new-nav"]

    getRole: (user) ->
      partner = user.relationships["primary-partner"]

      if user.meta["is-admin"]
        "site admin"
      else if partner?.data?.meta?.role == "admin"
        "partner admin"
      else if partner?.data
        partner.data?.meta?.role || "course learner"
      else
        "organic learner"

    getSegmentation: (user) ->
      partner = user.relationships["primary-partner"]
      {
        stage: partner?.data?.attributes?["stage"]
        is_paid: partner?.data?.attributes?["is-paid"]
        segment: partner?.data?.attributes?["segment"]
        vertical: partner?.data?.attributes?["vertical"]
        is_mooc: partner?.data?.attributes?["is-mooc"]
        is_courseware: partner?.data?.attributes?["is-courseware"]
        size: partner?.data?.attributes?["size"]
        learn_version: partner?.data?.attributes?["partner-settings"]?["learn-version"]
        is_new_nav: partner?.data?.attributes?["partner-settings"]?["show-new-nav"],

      }

    addHotJar: () ->
      node = document.createElement('script')
      node.innerHTML = "(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:1480379,hjsv:6};
          a=o.getElementsByTagName(\'head\')[0];
          r=o.createElement(\'script\');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,\'https://static.hotjar.com/c/hotjar-\',\'.js?sv=\');"
      node.type = 'text/javascript'
      node.async = true
      node.charset = 'utf-8'
      document.getElementsByTagName('head')[0].appendChild(node)

      node.type = 'text/javascript'
      node.async = true
      node.charset = 'utf-8'
      document.getElementsByTagName('head')[0].appendChild(node)

    pingSegment: (partner_id, segmentation, role, user) ->
      # Send information to Segment about v4 users only
      if segmentation.learn_version == 4 && !_.includes(process.env.RAILS_ENV, 'govcloud')

        integrations = {
          'Hubspot': false # Hubspot shall not receive anything from angular anymore. React events are sufficient for now.
        }

        traits = {
          'partner': partner_id,
          'stage': segmentation.stage,
          'created_at': user.attributes['created-at'],
          'primary_partner_role': role,
          'locale': user.attributes.locale,
          'learn_app_version': segmentation.learn_version,
          'name': user.attributes.name,
          'email': user.attributes.email,
          'is_new_nav': segmentation.is_new_nav

        }

        window.analytics.identify(user.id, traits, { 'integrations': integrations } )
        window.analytics.page({ 'userId': user.id }, { 'integrations': integrations})

    trackSegment: (event_name, attributes) ->
      if $scope.getSegmentation($scope.currentUser).learn_version == 4 && !_.includes(process.env.RAILS_ENV, 'govcloud')
        integrations = {
          'Hubspot': false # Hubspot shall not receive anything from angular anymore. React events are sufficient for now.
        }
        window.analytics.track(event_name, attributes, { 'integrations': integrations })

    pingAmplitude: (partner_id, stage, created_at, role, locale, learn_version) ->
      identify = new amplitude?.Identify()
        .set('partner', partner_id)
        .set('stage', stage)
        .set('created_at', created_at)
        .set('primary_partner_role', role)
        .set('locale', locale)
        .set('learn_app_version', learn_version)
      AmplitudeService.identify(identify)

    loadUser: (unauthenticatedRedirect) ->
      unless $scope.deferred
        $scope.deferred = $q.defer()

        date = new Date
        setCookie("timezone_offset", date.getTimezoneOffset())

        MyProfileResource.get include: "user_partner_member_ids,user_partner_member_ids.partner,user_partner_member_ids.partner.image,emails,image,primary-partner.image,pro-partner.image,plus-partner.image,plus-partner.account.plan,visible-libraries,visible-libraries.partner"
        , (success) ->
          $scope.currentUser = V3ModelHelper.parse(success)

          # Transition existing sessions by forcing a Keepalive and the creation of a _cerego_user cookie if one doesn't exist
          cookie = new UserCookie($cookies)
          Keepalive.ping() unless cookie.sessionExpiresAt

          partner = $scope.currentUser.relationships['primary-partner']
          partner_id   = partner?.data?.id || 0
          segmentation = $scope.getSegmentation($scope.currentUser)
          role = $scope.getRole($scope.currentUser)

          $timeout ->
            # setUsername needs to be in a delayed callback
            # see: angulartics/angulartics-google-analytics#18
            if _.includes(process.env.RAILS_ENV, 'govcloud')
              $analytics.setUsername($scope.currentUser.attributes.username)
            else
              AmplitudeService.setUserId($scope.currentUser.id)
              $analytics.setUsername($scope.currentUser.id)
              $scope.pingSegment(partner_id, segmentation, role, $scope.currentUser)

          $analytics.setUserProperties(dimension1: partner_id, dimension2: segmentation.stage, dimension3: role)
          $scope.pingAmplitude(partner_id, segmentation.state, $scope.currentUser.attributes['created-at'], role, $scope.currentUser.attributes.locale, segmentation.learn_version)

          if segmentation.stage == 'Free Trial'
            $scope.addHotJar()
            onV3Dashboard = $window.location.pathname == Packs.iKnowRoutes.v3_dashboard_path()
            onSetEdit = $window.location.pathname.match(/edit\/sets\/\d*/) != null

          if localStorageService.get("UserManager::userId")?.toString() != $scope.currentUser.id?.toString()
            tryClearLocalStorage()
            localStorageService.set("UserManager::userId", $scope.currentUser.id)

          amplitudeSessionId = amplitude?.getInstance()?._sessionId
          if amplitudeSessionId && localStorageService.get("UserManager::amplitudeSessionId")?.toString() != amplitudeSessionId.toString()
            localStorageService.set("UserManager::amplitudeSessionId", amplitudeSessionId)
            AmplitudeService.logEvent('new_session')
          $scope.currentUser.initialized = true
          $scope.deferred.resolve($scope.currentUser)
          if window.zE && _.isFunction(zE.hide) && $scope.currentUser.relationships["primary-partner"].data?.slug == 'biblemesh'
            zE.hide()

        , (error) ->
          if error.status == 401
            tryClearLocalStorage()
            $scope.currentUser.initialized = true
            if unauthenticatedRedirect
              $window.location.href = Packs.iKnowRoutes.v3_signin_path()
            $scope.deferred.resolve(false)
          else
            $scope.deferred.reject(error)

      $scope.deferred.promise

    isCAISUser: ->
      partner = $scope.currentUser?.relationships?["primary-partner"];
      partner?.data?.id == 7106 || partner?.data?.attributes?["parent-id"] == 7106

    resetUser: ->
      $scope.deferred = null
      $scope.loadUser()

    featureFlagSiteAdminOnly: (callback) ->
      $scope.loadUser().then ->
        callback() if $scope.isAdmin()

    updatePrimaryPartner: (partnerId) ->
      UsersResource.save({ id: $scope.currentUser.id }, { primary_partner_id: partnerId }).$promise

  $scope
])
