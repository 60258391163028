ceregoLayoutApp.service("SetExportService", ['$rootScope', '$q', '$timeout', 'DebugHelper', 'SetExportsResource', ($rootScope, $q, $timeout, DebugHelper, SetExportsResource) ->
  $scope = $rootScope.$new()

  DebugHelper.register("SetExportService", $scope)

  _.assignIn $scope,
    export: (module) ->
      deferred = $q.defer()

      onSuccess = (success) ->
        setExport = success.response
        if setExport.job_finished_at
          deferred.resolve(setExport)
        else
          deferred.notify(setExport)
          $timeout ->
            SetExportsResource.get
              id: setExport.id
            , onSuccess
            , deferred.reject
          , 1000

      SetExportsResource.create
        set_id: module.id
      , onSuccess
      , deferred.reject

      deferred.promise

  $scope
])
